import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function List(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.824 14.648">
        <path
            d="M2.842 11.807a1.111 1.111 0 0 0-.82-.322 1.111 1.111 0 0 0-.82.322 1.111 1.111 0 0 0-.322.82 1.134 1.134 0 0 0 .337.835 1.1 1.1 0 0 0 .806.337 1.1 1.1 0 0 0 .806-.337 1.134 1.134 0 0 0 .337-.835 1.111 1.111 0 0 0-.324-.82zm-2.256-.586a1.967 1.967 0 0 1 1.436-.615 1.942 1.942 0 0 1 1.421.6 1.942 1.942 0 0 1 .6 1.421 1.942 1.942 0 0 1-.6 1.421 1.942 1.942 0 0 1-1.421.6 1.942 1.942 0 0 1-1.422-.6 1.942 1.942 0 0 1-.6-1.421 1.918 1.918 0 0 1 .586-1.406zm2.256-4.688a1.064 1.064 0 0 0-.82-.352 1.086 1.086 0 0 0-.82.337 1.125 1.125 0 0 0-.322.806 1.125 1.125 0 0 0 .32.806 1.086 1.086 0 0 0 .82.337 1.086 1.086 0 0 0 .82-.337 1.125 1.125 0 0 0 .322-.806 1.075 1.075 0 0 0-.32-.791zM.586 5.889A2.016 2.016 0 0 1 2.021 5.3a1.942 1.942 0 0 1 1.421.6 1.942 1.942 0 0 1 .6 1.421 1.942 1.942 0 0 1-.6 1.421 1.942 1.942 0 0 1-1.421.6A1.967 1.967 0 0 1 .6 8.76 1.928 1.928 0 0 1 0 7.324a1.952 1.952 0 0 1 .586-1.435zM2.842 1.2a1.111 1.111 0 0 0-.82-.322 1.111 1.111 0 0 0-.82.322 1.111 1.111 0 0 0-.322.82 1.111 1.111 0 0 0 .322.82 1.111 1.111 0 0 0 .82.322 1.111 1.111 0 0 0 .82-.322 1.111 1.111 0 0 0 .322-.82 1.111 1.111 0 0 0-.322-.82zM.586.615A1.967 1.967 0 0 1 2.021 0a1.942 1.942 0 0 1 1.421.6 1.942 1.942 0 0 1 .6 1.421 1.942 1.942 0 0 1-.6 1.421 1.942 1.942 0 0 1-1.421.6 1.942 1.942 0 0 1-1.421-.6A1.942 1.942 0 0 1 0 2.021 1.918 1.918 0 0 1 .586.615zM14.3 7.852H5.6a.53.53 0 0 1-.366-.146.491.491 0 0 1-.161-.381.491.491 0 0 1 .161-.381A.53.53 0 0 1 5.6 6.8h8.7a.515.515 0 0 1 .381.146.515.515 0 0 1 .146.381.515.515 0 0 1-.146.381.515.515 0 0 1-.381.144zm0-5.3H5.6a.5.5 0 0 1-.366-.161.5.5 0 0 1-.161-.366.5.5 0 0 1 .161-.366.5.5 0 0 1 .366-.161h8.7a.491.491 0 0 1 .381.161.53.53 0 0 1 .146.366.53.53 0 0 1-.146.366.491.491 0 0 1-.381.158zm0 10.605H5.6a.5.5 0 0 1-.366-.161.5.5 0 0 1-.161-.366q0-.5.527-.5h8.7q.527 0 .527.5a.53.53 0 0 1-.146.366.491.491 0 0 1-.381.158z"/>
    </svg>

}