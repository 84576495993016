import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function StateDone(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M10.752 5.508a.287.287 0 0 1 0 .439L7.061 10.2q-.205.293-.439.059L4.248 8.174a.256.256 0 0 1 0-.41q.205-.322.439-.088l2.139 1.875 3.486-3.984q.206-.294.44-.059zM12.8 12.8A7.226 7.226 0 0 1 7.5 15a7.226 7.226 0 0 1-5.3-2.2A7.226 7.226 0 0 1 0 7.5a7.226 7.226 0 0 1 2.2-5.3A7.226 7.226 0 0 1 7.5 0a7.226 7.226 0 0 1 5.3 2.2A7.226 7.226 0 0 1 15 7.5a7.226 7.226 0 0 1-2.2 5.3zM7.5.615a6.64 6.64 0 0 0-4.878 2.007A6.64 6.64 0 0 0 .615 7.5a6.606 6.606 0 0 0 2.036 4.863A6.645 6.645 0 0 0 7.5 14.385a6.621 6.621 0 0 0 4.849-2.036A6.621 6.621 0 0 0 14.385 7.5a6.645 6.645 0 0 0-2.021-4.849A6.606 6.606 0 0 0 7.5.615z"/>
    </svg>

}