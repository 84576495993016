import {CALL_API, METHOD_GET} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";


// --------------------------------------------------------
// ---- Load issue with id
// --------------------------------------------------------

export const ISSUE_REQUEST = "ISSUE_REQUEST";
export const ISSUE_SUCCESS = "ISSUE_SUCCESS";
export const ISSUE_FAILURE = "ISSUE_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const issueRequest = (issueId: string) => ({
    [CALL_API]: {
        types: [ISSUE_REQUEST, ISSUE_SUCCESS, ISSUE_FAILURE],
        endpoint: `issues/` + issueId,
        schema: Schemas.ISSUE,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const loadIssue = (issueId: string) => (dispatch: any) => {
    return dispatch(issueRequest(issueId))
};

// --------------------------------------------------------
// ---- Changes detail tab
// --------------------------------------------------------

export const DETAIL_TAB_CHANGE = "DETAIL_TAB_CHANGE";

export const changeDetailTab = (tabId: number) => {
    return { type: DETAIL_TAB_CHANGE, data: tabId }
};

// --------------------------------------------------------
// ---- Load issue events by issue id
// --------------------------------------------------------

export const ISSUE_EVENTS_REQUEST = "ISSUE_EVENTS_REQUEST";
export const ISSUE_EVENTS_SUCCESS = "ISSUE_EVENTS_SUCCESS";
export const ISSUE_EVENTS_FAILURE = "ISSUE_EVENTS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const issueEventsRequest = (issueId: string) => ({
    [CALL_API]: {
        types: [ISSUE_EVENTS_REQUEST, ISSUE_EVENTS_SUCCESS, ISSUE_EVENTS_FAILURE],
        endpoint: `issues/` + issueId + `/events`,
        params: {"entityFlag": "all", "expanded": "userData", "size": 999},
        parentId: issueId,
        schema: Schemas.EVENTS,
        method: METHOD_GET
    }
});

/**
 * Performs authentication request
 */
export const loadIssueEvents = (issueId: string) => (dispatch: any) => {
    return dispatch(issueEventsRequest(issueId))
};

// --------------------------------------------------------
// ---- Open issue dialog
// --------------------------------------------------------

export const OPEN_ISSUE_DIALOG = "OPEN_ISSUE_DIALOG";

export const openIssueDialog = () => {
    return { type: OPEN_ISSUE_DIALOG }
};

// --------------------------------------------------------
// ---- Close issue dialog
// --------------------------------------------------------

export const CLOSE_ISSUE_DIALOG = "CLOSE_ISSUE_DIALOG";

export const closeIssueDialog = () => {
    return { type: CLOSE_ISSUE_DIALOG }
};