import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function StateMail(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M2.2 2.2A7.226 7.226 0 0 1 7.5 0a7.226 7.226 0 0 1 5.3 2.2A7.226 7.226 0 0 1 15 7.5a7.226 7.226 0 0 1-2.2 5.3A7.226 7.226 0 0 1 7.5 15a7.226 7.226 0 0 1-5.3-2.2A7.226 7.226 0 0 1 0 7.5a7.226 7.226 0 0 1 2.2-5.3zM7.5.618a6.818 6.818 0 0 0-3.193.762 6.341 6.341 0 0 0-2.373 2.109l5.625 5.622 5.566-5.537a7.216 7.216 0 0 0-2.446-2.153A6.544 6.544 0 0 0 7.5.615zM1.553 4.072A6.408 6.408 0 0 0 .615 7.5a6.509 6.509 0 0 0 1 3.486l3.453-3.427zm2.812 9.551a6.7 6.7 0 0 0 3.135.762 6.5 6.5 0 0 0 3.135-.791 7 7 0 0 0 2.432-2.168L9.551 7.939 7.764 9.756l-.088.059h-.059a.222.222 0 0 1-.293-.059L5.508 7.939l-3.574 3.575a6.834 6.834 0 0 0 2.431 2.109zM9.99 7.559l3.457 3.369a6.92 6.92 0 0 0 .938-3.428 6.3 6.3 0 0 0-.937-3.369z"/>
    </svg>

}