import {AUTHENTICATION_REQUEST, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILURE} from "../actions";
import {createSession} from "../../../session/Session";

const initialState = {
    authenticationLoading: false,
    authenticationError: false,
    authenticationSuccess: false,
};

const authentication = (state = initialState, action: any) => {
    switch (action.type) {
        case AUTHENTICATION_REQUEST:
            return {
                ...state,
                authenticationLoading: true,
                authenticationError: false,
                authenticationSuccess: false
            };
        case AUTHENTICATION_SUCCESS:
            createSession(action.response);
            return {
                ...state,
                authenticationLoading: false,
                authenticationError: false,
                authenticationSuccess: true
            };
        case AUTHENTICATION_FAILURE:
            return {
                ...state,
                authenticationLoading: false,
                authenticationError: true,
                authenticationSuccess: false,
            };
        default:
            return state
    }
};

export default authentication
