import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography, Grid, TextField, MenuItem, DialogTitle, IconButton
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import cs from "../../../localization/cs";
import {
    determineCompanyRole,
    determineProjectRole,
    ROLE_SUPPLIER,
    userRoleIds,
    userRoles
} from "../../../service/UserRulesService";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch, useSelector} from "react-redux";
import {loadCompanyUsers} from "../actions";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 22,
        color: theme.palette.primary.main,
        textAlign: "left"
    },
    input: {
        width: "100%",
        marginBottom: 20,
    },
    nameTitle: {
        fontSize: 22,
        fontWeight: "bold",
        color: theme.palette.primary.main,
    }
}));

/**
 * Wrapper of snackbar component
 */
export default function UserDialog(props: any) {

    const {
        editedUser,
        companyId,
        ignored,
        opened,
        onSubmit
    } = props;

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const baseRole = editedUser ? (
        props.forCompany ? determineCompanyRole(editedUser.rules) : determineProjectRole(editedUser.rules)
    ) : ROLE_SUPPLIER

    const classes = useStyles();

    const [user, setUser] = useState<string | null>(editedUser ? editedUser.user.uuid : "");
    const [role, setRole] = useState<number>(baseRole || ROLE_SUPPLIER);

    const {
        companies
        // @ts-ignore
    } = useSelector(state => state.claimit.company)

    const {
        companyUsers
        // @ts-ignore
    } = useSelector(state => state.claimit.user)

    const dispatch = useDispatch();

    useEffect(() => {
        if (companyId) {
            dispatch(loadCompanyUsers(companyId))
        }
        // eslint-disable-next-line
    }, [companyId])

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleClose = () => {
        props.onClose()
    };

    const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser(event.target.value);
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(parseInt(event.target.value));
    };

    const handleSubmit = () => {
        if (user !== "") {
            onSubmit({user: companyUsers[user].user, role})
            setUser("")
            setRole(ROLE_SUPPLIER)
        }
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={opened}
            // do not close dialog when click away to prevent losing filled data
            onClose={() => {
            }}
            aria-labelledby="project-dialog"
        >
            <DialogTitle style={{textAlign: "right"}}>
                <IconButton
                    edge="end"
                    onClick={handleClose}
                    color="inherit"
                    size="small"
                >
                    <CloseIcon/>
                </IconButton>
                <span className={classes.title}>
                    {editedUser ? cs.user_form_title_edit : cs.user_form_title_new}
                </span>
                {editedUser ?
                    <Typography variant="h6" noWrap className={classes.nameTitle}>
                        {editedUser.user.name + " " + editedUser.user.surname}
                    </Typography> : null}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    {!editedUser && companyId && companies && companies[companyId] && companies[companyId].userIds ?
                        <Grid item xs={12}>
                            <TextField
                                id="user-uuid"
                                select
                                required
                                label={cs.user_form_input_user}
                                value={user}
                                onChange={handleUserChange}
                                variant="outlined"
                                className={classes.input}
                            >
                                {companies[companyId].userIds
                                    .filter((id: string) => ignored.indexOf(id) === -1) // filter ignored ids
                                    .map((id: string) => companyUsers[id]) // map to user objects
                                    .map((user: any) => // map to jsx node
                                        <MenuItem key={user.user.uuid} value={user.user.uuid}>
                                            {user.user.name + " " + user.user.surname}
                                        </MenuItem>
                                )}
                            </TextField>
                        </Grid> : null
                    }
                    <Grid item xs={12}>
                        <TextField
                            id="user-role"
                            select
                            required
                            label={cs.user_form_input_role}
                            value={role}
                            onChange={handleRoleChange}
                            // helperText="Please select your currency"
                            variant="outlined"
                            className={classes.input}
                        >
                            {userRoleIds.map((roleId: number) => {
                                // @ts-ignore
                                let role = userRoles[roleId];
                                return (
                                    <MenuItem key={roleId} value={roleId}>
                                        {role.roleName}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    {editedUser ? cs.project_form_edit : cs.project_form_create}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

