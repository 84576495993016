import {applyMiddleware, combineReducers, createStore} from 'redux';
import {createBrowserHistory} from 'history'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import api from '../middleware/api'
import rootReducer from "../reducers";


// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();
const historyMiddleware = routerMiddleware(history);

// Create a logger middleware
// const loggerMiddleware = createLogger();

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
export const store = createStore(
    combineReducers({
        claimit: rootReducer,
        router: connectRouter(history)
    }),
    composeWithDevTools(applyMiddleware(historyMiddleware, thunk, api))
);