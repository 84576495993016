import React, {useEffect} from 'react';
import {Box, CircularProgress, Container} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';
import {loadCompanies} from "../actions";
import {idsToObjects} from "../../../middleware/schema/schemaUtil";
import CompanyItem from "./CompanyItem";
import {push} from "connected-react-router";
import routes from "../../../model/routes";


// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
}));

/**
 * Wrapper of snackbar component
 */
export default function Companies() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const dispatch = useDispatch();

    const {
        companiesLoading,
        companies,
        companyIds,
        companyPages
    } = // @ts-ignore
        useSelector(state => state.claimit.company);

    useEffect(() => {
        if (companyIds && companyIds.length === 1) {
            dispatch(push(routes.CompanyProjects.createRoute(companyIds[0])))
        }
    });

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const loadItems = (page: number) => {
        // pages starts from 0 on the server
        let normalizedPage = page -1;

        dispatch(loadCompanies(normalizedPage))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Container>
            <Box my={2} className={classes.wrapper}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadItems}
                    hasMore={!companiesLoading && (companyPages === null || ((companyPages.number + 1) !== companyPages.totalPages))}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        boxSizing: "border-box"
                    }}
                >
                    {idsToObjects(companyIds, companies).map((c: any) =>
                            <CompanyItem company={c} key={c.uuid}/>)
                       }
                    {companiesLoading ? <CircularProgress size={30} color="primary" style={{margin: 20}}/> : null}
                </InfiniteScroll>
            </Box>
        </Container>
    );
}

