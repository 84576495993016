import {CALL_API, METHOD_DELETE, METHOD_GET, METHOD_POST} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";


// --------------------------------------------------------
// ---- Load labels for project with id
// --------------------------------------------------------

export const LABELS_REQUEST = "LABELS_REQUEST";
export const LABELS_SUCCESS = "LABELS_SUCCESS";
export const LABELS_FAILURE = "LABELS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const labelsRequest = (projectId: string) => ({
    [CALL_API]: {
        types: [LABELS_REQUEST, LABELS_SUCCESS, LABELS_FAILURE],
        endpoint: `projects/` + projectId + `/labels`,
        parentId: projectId,
        params: {"size": 999},
        schema: Schemas.LABELS,
        method: METHOD_GET
    }
});

/**
 * Performs authentication request
 */
export const loadLabels = (projectId: string) => (dispatch: any) => {
    return dispatch(labelsRequest(projectId))
};

// --------------------------------------------------------
// ---- Create new label for project with id
// --------------------------------------------------------

interface NewLabel {
    color: string,
    name: string
}

export const LABELS_CREATE_REQUEST = "LABELS_CREATE_REQUEST";
export const LABELS_CREATE_SUCCESS = "LABELS_CREATE_SUCCESS";
export const LABELS_CREATE_FAILURE = "LABELS_CREATE_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const createLabelRequest = (projectId: string, data: NewLabel) => ({
    [CALL_API]: {
        types: [LABELS_CREATE_REQUEST, LABELS_CREATE_SUCCESS, LABELS_CREATE_FAILURE],
        endpoint: `projects/` + projectId + `/labels`,
        parentId: projectId,
        body: data,
        schema: Schemas.LABEL,
        method: METHOD_POST,
        paged: false
    }
});

/**
 * Performs request
 */
export const createLabel = (projectId: string, data: NewLabel) => (dispatch: any) => {
    return dispatch(createLabelRequest(projectId, data))
};

// --------------------------------------------------------
// ---- Delete label with id
// --------------------------------------------------------

export const LABELS_DELETE_REQUEST = "LABELS_DELETE_REQUEST";
export const LABELS_DELETE_SUCCESS = "LABELS_DELETE_SUCCESS";
export const LABELS_DELETE_FAILURE = "LABELS_DELETE_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const deleteLabelRequest = (projectId: string, labelId: string) => ({
    [CALL_API]: {
        types: [LABELS_DELETE_REQUEST, LABELS_DELETE_SUCCESS, LABELS_DELETE_FAILURE],
        endpoint: `labels/` + labelId,
        parentId: {
            projectId,
            labelId
        },
        method: METHOD_DELETE,
        paged: false
    }
});

/**
 * Performs request
 */
export const deleteLabel = (projectId: string, labelId: string) => (dispatch: any) => {
    return dispatch(deleteLabelRequest(projectId, labelId))
};