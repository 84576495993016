import cs from "../localization/cs";

const companyRules = [
    "PROJECT_CREATE",
    "PROJECT_AUTO_ADMIN",
    "COMPANY_SHOW",
    "COMPANY_EDIT",
    "COMPANY_DELETE",
    "COMPANY_ADD_USER",
    "COMPANY_RULE_USER_CHANGE",
    "COMPANY_RECOVER_DELETED",
    "PAYMENTS_SHOW",
    "PAYMENTS_PAY"
];

const projectRules = [
    "ISSUE_CREATE",
    "ISSUE_SHOW_MINE",
    "ISSUE_SHOW_ASSIGNED",
    "ISSUE_SHOW_FOREIGN",
    "ISSUE_EDIT",
    "ISSUE_STATUS_CHANGE_AS_ASSIGNEE",
    "ISSUE_STATUS_CHANGE_AS_SUPPLIER",
    "ISSUE_SUPPLIER_CHANGE",
    "ISSUE_ADD_EVENT",
    "ISSUE_DELETE",
    "ISSUE_RECOVER_DELETED",
    "ISSUE_CAN_SUPPLY",
    "PART_CREATE",
    "PART_EDIT",
    "PART_DELETE",
    "PART_SHOW",
    "PART_RECOVER_DELETED",
    "PROJECT_SHOW",
    "PROJECT_EDIT",
    "PROJECT_ASSIGNEE_ADD",
    "PROJECT_SUPPLIER_ADD",
    "PROJECT_ADMIN_ADD",
    "PROJECT_INVESTOR_ADD",
    "PROJECT_RULE_ASSIGNEE_CHANGE",
    "PROJECT_RULE_SUPPLIER_CHANGE",
    "PROJECT_RULE_ADMIN_CHANGE",
    "PROJECT_RULE_INVESTOR_CHANGE",
    "PROJECT_DELETE",
    "PROJECT_RECOVER_DELETED",
    "LABEL_CREATE",
    "LABEL_DELETE",
    "LABEL_RECOVER_DELETED",
    "LABEL_EDIT",
    "LABEL_SHOW_RELATED",
    "LABEL_SHOW_NOTRELATED"
];

export const ROLE_ASSIGNEE_ADMIN = 0;
export const ROLE_ASSIGNEE = 1;
export const ROLE_SUPPLIER = 2;
export const ROLE_INVESTOR = 3;

export const userRoleIds = [ROLE_ASSIGNEE_ADMIN, ROLE_ASSIGNEE, ROLE_SUPPLIER, ROLE_INVESTOR]

export const userRoles = {
    [ROLE_ASSIGNEE_ADMIN]: {
        id: ROLE_ASSIGNEE_ADMIN,
        projectRulesMap: "11111111111111111111111111111111111",
        companyRulesMap: "1111111111",
        roleName: cs.role_assignee_admin,
    },
    [ROLE_ASSIGNEE]: {
        id: ROLE_ASSIGNEE,
        projectRulesMap: "11111111111111111110100000011111111",
        companyRulesMap: "1010000000",
        roleName: cs.role_assignee
    },
    [ROLE_SUPPLIER]: {
        id: ROLE_SUPPLIER,
        projectRulesMap: "01100010100100010100000000000000010",
        companyRulesMap: "0010000000",
        roleName: cs.role_supplier
    },
    [ROLE_INVESTOR]: {
        id: ROLE_INVESTOR,
        projectRulesMap: "01110000000000010100000000000000010",
        companyRulesMap: "0010000000",
        roleName: cs.role_investor
    },
}

const transformProjectRulesToString = (rules: Array<string>): string => {
    let rulesString = '';
    projectRules.forEach((rule) => {
        rulesString += rules.indexOf(rule) > -1 ? '1' : '0';
    });
    return rulesString;
};

export const getProjectRulesForRole = (roleKey: number): Array<string> => {

    let rulesList = [];
    // @ts-ignore
    let roleMap = userRoles[roleKey].projectRulesMap;

    let i = 0;
    for (; i<roleMap.length; i++){
        if (parseInt(roleMap.charAt(i), 10)===1){
            rulesList.push(projectRules[i]);
        }
    }

    return rulesList;
}

export const determineProjectRole = (rules: Array<string>): any | null => {

    const rulesString = transformProjectRulesToString(rules)

    let finalRole = null;

    userRoleIds.forEach((roleId) => {
        // @ts-ignore
        let role = userRoles[roleId];

        // @ts-ignore
        if (role.projectRulesMap === rulesString) {
            // @ts-ignore
            finalRole = role;
        }
    });
    return finalRole
}

const transformCompanyRulesToString = (rules: Array<string>): string => {
    let rulesString = ``;
    companyRules.forEach((rule) => {
        rulesString += rules.indexOf(rule) > -1 ? '1' : '0';
    });
    return rulesString;
};

export const determineCompanyRole = (rules: Array<string>) => {

    const rulesString = transformCompanyRulesToString(rules)

    userRoleIds.forEach((roleId) => {
        // @ts-ignore
        // eslint-disable-next-line eqeqeq
        if (userRoles[roleId].companyRulesMap == rulesString) {
            // @ts-ignore
            return userRoles[roleId];
        }
    });
    return null
}