import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Users(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.148 12.824">
        <path
            d="M9.16 2.215A2.138 2.138 0 0 1 9.8.644a2.138 2.138 0 0 1 1.572-.643 2.161 2.161 0 0 1 1.586.656 2.161 2.161 0 0 1 .656 1.586 2.116 2.116 0 0 1-.656 1.572 2.184 2.184 0 0 1-1.586.643 2.592 2.592 0 0 1-.93-.219A2.589 2.589 0 0 0 9.16 2.215zm.109 3.91a2.072 2.072 0 0 1-1.559.656 2.161 2.161 0 0 1-1.586-.656 2.161 2.161 0 0 1-.655-1.581 2.116 2.116 0 0 1 .656-1.572 2.184 2.184 0 0 1 1.586-.643 2.138 2.138 0 0 1 1.572.643 2.138 2.138 0 0 1 .643 1.572 2.161 2.161 0 0 1-.656 1.581zm-.629.793a2.717 2.717 0 0 1 2.01.848 2.817 2.817 0 0 1 .834 2.051v2.3h-.027l-.164.082a7.653 7.653 0 0 1-3.393.625 12.991 12.991 0 0 1-3.855-.629l-.162-.081V9.789a2.749 2.749 0 0 1 .847-2.037 2.785 2.785 0 0 1 2.023-.834zm3.691-2.3a2.717 2.717 0 0 1 2 .82 2.717 2.717 0 0 1 .82 2v2.324h-.027l-.137.055a1.48 1.48 0 0 1-.369.178 7.125 7.125 0 0 1-1.053.273 9.365 9.365 0 0 1-1.565.176V9.76a3.253 3.253 0 0 0-.67-2.01A3.36 3.36 0 0 0 9.6 6.535a2.743 2.743 0 0 0 .848-1.914zM3.8 4.457a2.126 2.126 0 0 1-1.586-.643 2.162 2.162 0 0 1-.628-1.57A2.184 2.184 0 0 1 2.229.658 2.116 2.116 0 0 1 3.8 0a2.116 2.116 0 0 1 1.572.656 2.184 2.184 0 0 1 .644 1.588v.109A2.724 2.724 0 0 0 5 4.129a2.12 2.12 0 0 1-1.2.328zm1.97 2.078a3.36 3.36 0 0 0-1.737 1.217 3.475 3.475 0 0 0-.7 2.064v.684a14.912 14.912 0 0 1-3.172-.6L0 9.844V7.492a2.739 2.739 0 0 1 .834-2.01 2.771 2.771 0 0 1 2.037-.838h2.051a2.714 2.714 0 0 0 .848 1.891z"/>
    </svg>
}