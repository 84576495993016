import React from 'react';
import {useDispatch, useSelector} from 'react-redux'

import {green} from '@material-ui/core/colors';
import {hideSnackbar} from "../actions";
import {IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {SnackBarStyles} from "../reducers/snackbar";
import {makeStyles} from "@material-ui/core/styles";

const AUTO_HIDE_DURATION = 4000;

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));


/**
 * Wrapper of snackbar component
 */
export default function SnackBar() {

    const classes = useStyles();

    // @ts-ignore
    const snackbar = useSelector(state => state.claimit.snackbar);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideSnackbar())
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={snackbar.message !== ''}
            autoHideDuration={snackbar.autoHideDuration || AUTO_HIDE_DURATION}
            onClose={handleClose}
        >
            <SnackbarContent
                className={snackbar.type === SnackBarStyles.SUCCESS ? classes.success : classes.error}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                  {snackbar.message}
            </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon className={classes.icon}/>
                    </IconButton>
                ]}
            />
        </Snackbar>
    )
}

