import React from 'react';
import {Box, withStyles, Paper, Divider} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {changeDataTab, changeFilterLabels, changeFilterPriorityDue, changeFilterStates} from "../actions";
import LabelsInput from "../../label/components/LabelsInput";
import PartsIcon from "../../core/components/icons/Structure";
import IssuesIcon from "../../core/components/icons/List";
import PriorityIcon from "../../core/components/icons/Important";
import DueIcon from "../../core/components/icons/Clock";
import PlanIcon from "../../core/components/icons/Pin";
import StateNewIcon from "../../core/components/icons/StateNew";
import StateMailIcon from "../../core/components/icons/StateMail";
import StateEyeIcon from "../../core/components/icons/StateEye";
import StateBackIcon from "../../core/components/icons/StateBack";
import StateCrossIcon from "../../core/components/icons/StateCross";
import StateDoneIcon from "../../core/components/icons/StateDone";
import StateProgressIcon from "../../core/components/icons/StateProgress";
import {ISSUES_TAB_ID, PARTS_TAB_ID, PLAN_TAB_ID} from "../reducers";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        marginLeft: 20,
        marginRight: 20
    },
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        marginRight: 20,

    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    selectedTab: {
        color: "white !important",
        background: theme.palette.primary.main + " !important"
    },
    normalTab: {
        color: theme.palette.primary.main + " !important",
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.1),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

/**
 * Wrapper of snackbar component
 */
export default function Filters(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const tabId = // @ts-ignore
        useSelector(state => state.claimit.project.dataTabId);

    const {
        priorityDue,
        states,
        labels,
        // assignees,
        // suppliers
    } = // @ts-ignore
        useSelector(state => state.claimit.project.filter);

    const dispatch = useDispatch();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleTabId = (event: React.MouseEvent<HTMLElement>, newTabId: string) => {
        dispatch(changeDataTab(parseInt(newTabId) || 0))
    };

    const handlePriorityDueChange = (event: React.MouseEvent<HTMLElement>, newValues: string[]) => {
        dispatch(changeFilterPriorityDue(newValues))
    };

    const handleStatesChange = (event: React.MouseEvent<HTMLElement>, newStates: number[]) => {
        dispatch(changeFilterStates([...newStates]))
    };

    const handleLabelsChange = (newLabels: string[]) => {
        dispatch(changeFilterLabels([...newLabels]))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Box my={1} className={classes.wrapper}>

            <Paper elevation={1} className={classes.paper}>
                <StyledToggleButtonGroup
                    size="small"
                    value={tabId}
                    exclusive
                    onChange={handleTabId}
                    aria-label="text alignment"
                >
                    <ToggleButton value={PARTS_TAB_ID} aria-label="left aligned" className={tabId === 0 ? classes.selectedTab : classes.normalTab}>
                        <PartsIcon variant={tabId === PARTS_TAB_ID ? "secondary" : "primary"}/>
                    </ToggleButton>
                    <ToggleButton value={ISSUES_TAB_ID} aria-label="centered" className={tabId === 1 ? classes.selectedTab : classes.normalTab}>
                        <IssuesIcon variant={tabId === ISSUES_TAB_ID ? "secondary" : "primary"}/>
                    </ToggleButton>
                    <ToggleButton value={PLAN_TAB_ID} aria-label="right aligned" className={tabId === 2 ? classes.selectedTab : classes.normalTab}>
                        <PlanIcon variant={tabId === PLAN_TAB_ID ? "secondary" : "primary"}/>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Paper>

            <Paper elevation={1} className={classes.paper}>
                <StyledToggleButtonGroup
                    size="small"
                    value={priorityDue}
                    onChange={handlePriorityDueChange}
                    aria-label="text alignment"
                >
                    <ToggleButton value="priority" aria-label="left aligned" className={classes.normalTab}>
                        <PriorityIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value="due" aria-label="centered" className={classes.normalTab}>
                        <DueIcon variant="primary"/>
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <Divider flexItem orientation="vertical" className={classes.divider} />
                <StyledToggleButtonGroup
                    size="small"
                    value={states}
                    onChange={handleStatesChange}
                    aria-label="text formatting"
                >
                    <ToggleButton value={0} aria-label="state-new" className={classes.normalTab}>
                        <StateNewIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={1} aria-label="state-mail" className={classes.normalTab}>
                        <StateMailIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="state-eye" className={classes.normalTab}>
                        <StateEyeIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="state-progress" className={classes.normalTab}>
                        <StateProgressIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="state-back" className={classes.normalTab}>
                        <StateBackIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="state-done" className={classes.normalTab}>
                        <StateCrossIcon variant="primary"/>
                    </ToggleButton>
                    <ToggleButton value={6} aria-label="state-done" className={classes.normalTab}>
                        <StateDoneIcon variant="primary"/>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Paper>
            {props.project ? <Paper elevation={1} className={classes.paper}>
                <LabelsInput
                    project={props.project}
                    selected={labels}
                    onChange={handleLabelsChange}
                />
            </Paper> : null}

        </Box>
    );
}

