import {CALL_API, METHOD_GET} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";


// --------------------------------------------------------
// ---- Loads part items
// --------------------------------------------------------

export const PARTS_REQUEST = "PARTS_REQUEST";
export const PARTS_SUCCESS = "PARTS_SUCCESS";
export const PARTS_FAILURE = "PARTS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const partsRequest = (id: string, page: number) => ({
    [CALL_API]: {
        types: [PARTS_REQUEST, PARTS_SUCCESS, PARTS_FAILURE],
        endpoint: `parts/` + id + `/full`,
        params: {"page": page, "expanded": "issuesCount,userDetails,address,labels,lastState"},
        parentId: id,
        method: METHOD_GET
    }
});

/**
 * Performs parts request
 */
export const loadParts = (id: string, page: number) => (dispatch: any) => {
    return dispatch(partsRequest(id, page))
};

// --------------------------------------------------------
// ---- Loads parts issues
// --------------------------------------------------------

export const PARTS_ISSUES_REQUEST = "PARTS_ISSUES_REQUEST";
export const PARTS_ISSUES_SUCCESS = "PARTS_ISSUES_SUCCESS";
export const PARTS_ISSUES_FAILURE = "PARTS_ISSUES_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const partsIssuesRequest = (id: string, page: number) => ({
    [CALL_API]: {
        types: [PARTS_ISSUES_REQUEST, PARTS_ISSUES_SUCCESS, PARTS_ISSUES_FAILURE],
        endpoint: `parts/` + id + `/issues`,
        params: {"page": page, "cascade": "nested", "expanded": "userDetails,address,labels,lastState"},
        schema: Schemas.ISSUES,
        parentId: id,
        method: METHOD_GET
    }
});

/**
 * Performs parts request
 */
export const loadPartsIssues = (id: string, page: number) => (dispatch: any) => {
    return dispatch(partsIssuesRequest(id, page))
};

// --------------------------------------------------------
// ---- Loads part hierarchy
// --------------------------------------------------------

export const PART_BRANCH_REQUEST = "PART_BRANCH_REQUEST";
export const PART_BRANCH_SUCCESS = "PART_BRANCH_SUCCESS";
export const PART_BRANCH_FAILURE = "PART_BRANCH_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const partBranchRequest = (id: string) => ({
    [CALL_API]: {
        types: [PART_BRANCH_REQUEST, PART_BRANCH_SUCCESS, PART_BRANCH_FAILURE],
        endpoint: `parts/` + id + `/branch`,
        params: {"size": 999},
        parentId: id,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs parts request
 */
export const loadPartBranch = (id: string) => (dispatch: any) => {
    return dispatch(partBranchRequest(id))
};

// --------------------------------------------------------
// ---- Loads part
// --------------------------------------------------------

export const PART_REQUEST = "PART_REQUEST";
export const PART_SUCCESS = "PART_SUCCESS";
export const PART_FAILURE = "PART_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const partRequest = (id: string) => ({
    [CALL_API]: {
        types: [PART_REQUEST, PART_SUCCESS, PART_FAILURE],
        endpoint: `parts/` + id,
        schema: Schemas.PART,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs part request
 */
export const loadPart = (id: string) => (dispatch: any) => {
    return dispatch(partRequest(id))
};

// --------------------------------------------------------
// ---- Load latest plan of a part with id
// --------------------------------------------------------

export const PART_PLAN_LATEST_REQUEST = "PART_PLAN_LATEST_REQUEST";
export const PART_PLAN_LATEST_SUCCESS = "PART_PLAN_LATEST_SUCCESS";
export const PART_PLAN_LATEST_FAILURE = "PART_PLAN_LATEST_FAILURE";

const partPlanLatestRequest = (partId: string) => ({
    [CALL_API]: {
        types: [PART_PLAN_LATEST_REQUEST, PART_PLAN_LATEST_SUCCESS, PART_PLAN_LATEST_FAILURE],
        endpoint: `parts/` + partId + `/plans`,
        params: {"filter": "head"},
        schema: Schemas.PLAN,
        method: METHOD_GET,
        parentId: partId,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const loadPartPlanLatest = (partId: string) => (dispatch: any) => {
    return dispatch(partPlanLatestRequest(partId))
};

// --------------------------------------------------------
// ---- Insert part
// --------------------------------------------------------

export const PART_ADD = "PART_ADD";

export const addPart = (part: any) => {
    return { type: PART_ADD, data: part }
};