import React, {useState} from 'react';
import {
    Button,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import cs from "../../../localization/cs";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {

    },
    input: {
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0)"
        }
    },
    inputFocused: {
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main
        }
    },
    buttonsWrapperHidden: {
        display: "none"
    },
    buttonsWrapper: {
        display: "block"
    }
}));

interface EditableTextProps {
    text: string,
    onChangeCancel: () => void,
    onChangeComplete: (newText: string) => void,
    rows?: number,
    rowsMax?: number,
    style?: any,
    textFieldStyle?: any
}

export default function EditableText(props: EditableTextProps) {

    const {
        text,
        rows,
        rowsMax,
        onChangeCancel,
        onChangeComplete,
        style,
        textFieldStyle
    } = props

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const [focused, setFocused] = useState<boolean>(false)
    const [editedText, setEditedText] = useState(text)

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(event.target.value);
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return <div style={style} className={classes.wrapper}>
        <TextField
            id="project-description"
            multiline
            rows={rows || ""}
            rowsMax={rowsMax || 1}
            value={editedText}
            onChange={handleChange}
            className={focused ? classes.inputFocused : classes.input}
            onClick={() => {
                setFocused(true)
            }}
            variant="outlined"
            style={textFieldStyle || {}}
        />
        <div className={focused ? classes.buttonsWrapper : classes.buttonsWrapperHidden}>
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                    onChangeComplete(editedText)
                    setFocused(false)
                }}
            >
                {cs.general_action_save}
            </Button>
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                    setEditedText(text)
                    onChangeCancel()
                    setFocused(false)
                }}
            >
                {cs.general_action_cancel}
            </Button>
        </div>
    </div>
}

