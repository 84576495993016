import {
    CLOSE_ISSUE_DIALOG,
    DETAIL_TAB_CHANGE,
    ISSUE_EVENTS_FAILURE,
    ISSUE_EVENTS_REQUEST,
    ISSUE_EVENTS_SUCCESS,
    ISSUE_SUCCESS,
    OPEN_ISSUE_DIALOG,
} from "../actions";
import {PARTS_ISSUES_SUCCESS, PARTS_SUCCESS} from "../../part/actions";
import {normalize} from "normalizr";
import schemas from "../../../middleware/schemas";
import {insertItem, insertItems} from "../../../util/array";

const initialState = {
    issues: {},
    issueIds: [],
    detailTabId: 0,
    events: {},
    eventIds: [],
    issueDialogOpen: false
};


const issue = (state = initialState, action: any) => {
    switch (action.type) {
        // --------------------------------------------------------
        // ---- TAB related
        // --------------------------------------------------------
        case DETAIL_TAB_CHANGE:
            return {
                ...state,
                detailTabId: action.data
            };
        // --------------------------------------------------------
        // ---- PARTS
        // --------------------------------------------------------
        case PARTS_SUCCESS:

            let normalizedIssues = normalize(action.response.content.issues, schemas.ISSUES);

            return {
                ...state,
                issues: normalizedIssues.entities && normalizedIssues.entities.issue ?
                    {
                        ...state.issues,
                        ...normalizedIssues.entities.issue
                    } : {...state.issues},
                issueIds: normalizedIssues.result ? insertItems(state.issueIds, normalizedIssues.result) : [...state.issueIds],

            };
        case PARTS_ISSUES_SUCCESS:

            return {
                ...state,
                issues: {
                    ...state.issues,
                    ...action.response.content.entities.issue
                },
                issueIds: insertItems(state.issueIds, action.response.content.result),
            };
        // --------------------------------------------------------
        // ---- ISSUES
        // --------------------------------------------------------
        case ISSUE_SUCCESS:

            return {
                ...state,
                issues: {
                    ...state.issues,
                    ...action.response.entities.issue
                },
                issueIds: insertItem(state.issueIds, action.response.result)

            };

        // --------------------------------------------------------
        // ---- EVENTS
        // --------------------------------------------------------
        case ISSUE_EVENTS_REQUEST:

            return {
                ...state,
                issues: {
                    ...state.issues,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.issues[action.parentId],
                        eventsLoading: true
                    }
                },
            }

        case ISSUE_EVENTS_SUCCESS:

            return {
                ...state,
                issues: {
                    ...state.issues,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.issues[action.parentId],
                        // @ts-ignore
                        events: insertItems(state.issues[action.parentId].events || [], action.response.content.result),
                        eventsLoading: false
                    }
                },
                events: {
                    ...state.events,
                    ...action.response.content.entities.event
                },
                eventIds: insertItem(state.eventIds, action.response.content.result)

            };

        case ISSUE_EVENTS_FAILURE:

            return {
                ...state,
                issues: {
                    ...state.issues,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.issues[action.parentId],
                        eventsLoading: false
                    }
                },
            }
        // --------------------------------------------------------
        // ---- CREATE DIALOG
        // --------------------------------------------------------
        case OPEN_ISSUE_DIALOG:
            return {
                ...state,
                issueDialogOpen: true,
            }
        case CLOSE_ISSUE_DIALOG:
            return {
                ...state,
                issueDialogOpen: false
            }
        default:
            return state
    }
};

export default issue
