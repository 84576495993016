import {CURRENT_USER_REQUEST, CURRENT_USER_SUCCESS, CURRENT_USER_FAILURE} from "../actions";

const initialState = {
    user: null,
};

const dashboard = (state = initialState, action: any) => {
    switch (action.type) {
        case CURRENT_USER_REQUEST:
            return {
                ...state,
            };
        case CURRENT_USER_SUCCESS:
            return {
                ...state,
                user: action.response,
            };
        case CURRENT_USER_FAILURE:
            return {
                ...state,
            };
        default:
            return state
    }
};

export default dashboard
