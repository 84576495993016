import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Message(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.695 15">
        <path d="M1.641 13.799l.645-3.135A6.174 6.174 0 0 1 .674 8.789 4.923 4.923 0 0 1 0 6.24a5.431 5.431 0 0 1 2.344-4.424A8.7 8.7 0 0 1 7.881 0a8.7 8.7 0 0 1 5.537 1.816 5.431 5.431 0 0 1 2.344 4.424 5.456 5.456 0 0 1-2.344 4.453 8.715 8.715 0 0 1-5.537 1.79 8.367 8.367 0 0 1-2.959-.439zM12.979 2.315a8.151 8.151 0 0 0-5.1-1.611 8.151 8.151 0 0 0-5.1 1.611A4.8 4.8 0 0 0 .674 6.24a4.846 4.846 0 0 0 2.2 3.984l.176.146-.443 2.113 2.256-1.2.146.059a7.473 7.473 0 0 0 2.871.469 8.117 8.117 0 0 0 5.084-1.628 4.828 4.828 0 0 0 2.124-3.94 4.8 4.8 0 0 0-2.109-3.928zM16.67 15l-2.344-1.23a5.6 5.6 0 0 1-1.934.293 5.514 5.514 0 0 1-4.306-1.787l.5-.439a4.817 4.817 0 0 0 3.809 1.523 5.154 5.154 0 0 0 1.846-.293l.146-.059 1.318.7-.266-1.225.176-.117a3.051 3.051 0 0 0 1.377-2.52 3.237 3.237 0 0 0-1.933-2.873l.352-.615a4.479 4.479 0 0 1 1.684 1.525 3.585 3.585 0 0 1 .6 1.963 3.69 3.69 0 0 1-1.495 2.928zM3.633 5.303v-.645h8.5v.645zm0 1.377v-.674h6.475v.677zm0 1.436v-.645h5.6v.645z"/>
    </svg>
}