import cs from "../localization/cs";

export default {
  Authentication: {
    route: '/login',
    title: cs.authorization_title,
    value: 0
  },
  Dashboard: {
    route: '/',
    title: cs.dashboard_title,
    value: 1
  },
  CompanyProjects: {
    route: '/companies/:companyId/projects',
    title: cs.company_projects_title,
    value: 2,
    createRoute: (id) => {
      return '/companies/' + id + '/projects'
    }
  },
  Project: {
    route: '/projects/:projectId',
    title: cs.part_title,
    value: 3,
    createRoute: (id) => {
      return '/projects/' + id
    }
  },
  Part: {
    route: '/projects/:projectId/parts/:partId',
    title: cs.part_title,
    value: 4,
    createRoute: (projectId, id) => {
      return '/projects/' + projectId + '/parts/' + id
    },
    createRouteFromPath: (path, id) => {

    }
  },
  Issue: {
    route: '/projects/:projectId/issues/:issueId',
    title: cs.part_title,
    value: 5,
    createRoute: (projectId, id) => {
      return '/projects/' + projectId + '/issues/' + id
    }
  }
};
