import {useEffect} from "react";
import {changeCurrentIssue, changeCurrentPart} from "../actions";
import {loadIssue} from "../../issue/actions";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";


export function CurrentPartResolver(props: any) {

    // @ts-ignore
    const { currentPart } = useSelector(state => state.claimit.project);

    // @ts-ignore
    const { issues } = useSelector(state => state.claimit.issue);

    const {partId, issueId} = useParams();

    const dispatch = useDispatch();


    useEffect(() => {
        if ((currentPart === null && partId) || (currentPart !== null && partId && currentPart !== partId)) {
            dispatch(changeCurrentPart(partId));
            return;
        }
        if (!partId && !issueId && props.project) {
            dispatch(changeCurrentPart(props.project.mainPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partId]);

    useEffect(() => {

        dispatch(changeCurrentIssue(issueId || null));

        if (currentPart !== null && issueId && issues[issueId] && currentPart !== issues[issueId].part) {
            dispatch(changeCurrentPart(issues[issueId].part));
            return;
        }
        if (currentPart === null && issueId) {
            if (!issues[issueId]) {
                dispatch(loadIssue(issueId))
            } else {
                dispatch(changeCurrentPart(issues[issueId].part))
            }
            return;
        }
        if (!partId && !issueId && props.project) {
            dispatch(changeCurrentPart(props.project.mainPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    useEffect(() => {
        if (currentPart === null && issueId && issues[issueId]) {
            dispatch(changeCurrentPart(issues[issueId].part))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issues]);

    useEffect(() => {
        if (currentPart !== null && !partId && !issueId && props.project && currentPart !== props.project.mainPart) {
            dispatch(changeCurrentPart(props.project.mainPart));
            return;
        }

        if (!partId && !issueId && props.project) {
            dispatch(changeCurrentPart(props.project.mainPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.project]);

    return null;
}