import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function StateBack(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M12.8 12.8A7.226 7.226 0 0 1 7.5 15a7.226 7.226 0 0 1-5.3-2.2A7.226 7.226 0 0 1 0 7.5a7.226 7.226 0 0 1 2.2-5.3A7.226 7.226 0 0 1 7.5 0a7.226 7.226 0 0 1 5.3 2.2A7.226 7.226 0 0 1 15 7.5a7.226 7.226 0 0 1-2.2 5.3zM7.5.615a6.64 6.64 0 0 0-4.878 2.007A6.64 6.64 0 0 0 .615 7.5a6.606 6.606 0 0 0 2.036 4.863A6.645 6.645 0 0 0 7.5 14.385a6.621 6.621 0 0 0 4.849-2.036A6.621 6.621 0 0 0 14.385 7.5a6.645 6.645 0 0 0-2.021-4.849A6.606 6.606 0 0 0 7.5.615zm1.055 4.951a2.617 2.617 0 0 1 1.963.82 2.764 2.764 0 0 1 .791 1.992 2.712 2.712 0 0 1-.82 1.992 2.712 2.712 0 0 1-1.992.82H5.566a.285.285 0 0 1-.322-.322q0-.293.322-.293h2.989a2.116 2.116 0 0 0 1.553-.645 2.116 2.116 0 0 0 .645-1.553 2.116 2.116 0 0 0-.645-1.553 2.116 2.116 0 0 0-1.553-.645H4.688l1.26 1.26a.287.287 0 0 1 0 .439.287.287 0 0 1-.439 0L3.75 6.123a.264.264 0 0 1 0-.439l1.758-1.816a.264.264 0 0 1 .439 0 .287.287 0 0 1 0 .439l-1.26 1.26z"/>
    </svg>

}