export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';

/**
 * show snackbar actions
 *
 * @param message
 * @param duration
 * @param snackType
 * @returns {{type: string, message: *, duration: *, snackType: *}}
 */
export const showSnackbar = (message: String, duration: Number, snackType: String) => ({
    type: SHOW_SNACK_BAR,
    message,
    duration,
    snackType
});

/**
 * hide snackbar actions
 * @returns {{type: string}}
 */
export const hideSnackbar = () => ({
  type: HIDE_SNACK_BAR,
});

