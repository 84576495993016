import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Clock(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.828 14.722">
        <path d="M11.777 12.7a6.63 6.63 0 0 1-4.863 2.022 6.654 6.654 0 0 1-4.878-2.037A6.654 6.654 0 0 1 0 7.807a6.654 6.654 0 0 1 2.036-4.878A6.654 6.654 0 0 1 6.914.893a6.654 6.654 0 0 1 4.878 2.036 6.654 6.654 0 0 1 2.036 4.878 6.679 6.679 0 0 1-2.051 4.893zm-.5-9.258a5.89 5.89 0 0 0-4.365-1.816 5.962 5.962 0 0 0-4.38 1.8 5.962 5.962 0 0 0-1.8 4.38 5.962 5.962 0 0 0 1.8 4.38 5.962 5.962 0 0 0 4.38 1.8 5.962 5.962 0 0 0 4.38-1.8 5.962 5.962 0 0 0 1.8-4.38 5.89 5.89 0 0 0-1.813-4.364zm-5.1 1.23q0-.352.381-.352a.311.311 0 0 1 .352.352v3.633q0 .381-.352.381a.337.337 0 0 1-.381-.381zm0 3.721a.337.337 0 0 1 .381-.381H8.73a.337.337 0 0 1 .381.381q0 .352-.381.352H6.563a.364.364 0 0 1-.263-.117.338.338 0 0 1-.118-.235zm6.475-5.332A10.627 10.627 0 0 0 9.107.512Q9.898-.543 11.714.6q1.763 1.348.942 2.461zM4.742.6a9.654 9.654 0 0 0-3.574 2.461Q.348 1.948 2.106.6q1.908-1.201 2.64 0z"/>
    </svg>

}