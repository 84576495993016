import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    AppBar,
    Toolbar,
    Typography, IconButton, Tabs, Tab, Grid, TextField, MenuItem, Chip, useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {closeProjectDialog} from "../actions";
import CloseIcon from "@material-ui/icons/Close";
import cs from "../../../localization/cs";
import InfoIcon from "../../core/components/icons/Info";
import UsersIcon from "../../core/components/icons/Users";
import LabelsIcon from "../../core/components/icons/Labels";
import AddIcon from "../../core/components/icons/New";
import EditIcon from "../../core/components/icons/Edit";
import TrashIcon from "../../core/components/icons/Trash";

import {TabPanel, tabProps} from "../../issue/components/Issue";
import ImageIcon from "../../core/components/icons/Pictures";
import PlanIcon from "../../core/components/icons/Map";
import PinIcon from "../../core/components/icons/Pin";
import CardIconButton from "../../core/components/CardIconButton";
import {determineProjectRole, getProjectRulesForRole} from "../../../service/UserRulesService";
import UserDialog from "../../user/components/UserDialog";
import moment from "moment";
import {TwitterPicker} from "react-color";
import {addProjectUser, loadProjectUsers, removeProjectUser} from "../../user/actions";
import {createLabel, deleteLabel, loadLabels} from "../../label/actions";
import ConfirmDialog from "../../core/components/ConfirmDialog";
import {createProject} from "../../company/actions";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    wrapper: {
        transition: "width 2s, height 2s"
    },
    wrapperEdited: {
        "& div.MuiPaper-root": {
            height: "85%"
        },
        transition: "width 2s, height 2s"
    },
    appBar: {
        borderRadius: "4px 4px 0px 0px",
        background: "#0f5c98"
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        marginLeft: 20,
        display: "flex",
        alignItems: "center"
    },
    tab: {
        minWidth: 120,
        minHeight: 48,
        paddingTop: 0,
        "& span": {
            flexDirection: "row"
        },
        "& span > *:first-child": {
            marginBottom: "0px !important",
            marginRight: 6
        }
    },
    input: {
        width: "100%",
        marginBottom: 20,
    },
    addressTitle: {
        fontSize: 13,
        color: "#5a5a5a",
        marginLeft: 10,
        marginTop: 24
    },
    circle: {
        display: "block",
        width: 30,
        height: 30,
        background: "#c1deff",
        borderRadius: 15
    },
    labelsWrapper: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 10,
        border: "1px solid #707070",
        borderRadius: 6
    },
    label: {
        margin: 6
    }
}));

const IMAGE_BUTTON_HEIGHT = 130;

/**
 * Wrapper of snackbar component
 */
export default function ProjectDialog() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const theme = useTheme();

    const {
        projectDialogCompany,
        projectDialogOpen,
        editedProject,
        projectDialogTabId
    }
        // @ts-ignore
        = useSelector(state => state.claimit.project)

    const [tabId, setTabId] = useState(projectDialogTabId || 0);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [description, setDescription] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [company, setCompany] = useState<string | null>(null);

    const [userDialogOpened, setUserDialogOpened] = useState<boolean>(false);

    const [labelToBeDeleted, setLabelToBeDeleted] = useState<string | null>(null);

    const [newLabelText, setNewLabelText] = useState("");
    const [newLabelColor, setNewLabelColor] = useState("");

    const {
        companies,
        companyIds
    }
        // @ts-ignore
        = useSelector(state => state.claimit.company)

    const {
        projects
    }
        // @ts-ignore
        = useSelector(state => state.claimit.project)

    const {
        projectUsers
    }
        // @ts-ignore
        = useSelector(state => state.claimit.user)

    const {
        labels
    }
        // @ts-ignore
        = useSelector(state => state.claimit.label)

    const dispatch = useDispatch();

    useEffect(() => {
        setCompany(projectDialogCompany)
    }, [projectDialogCompany])

    useEffect(() => {
        if (editedProject) {
            dispatch(loadProjectUsers(editedProject))
            dispatch(loadLabels(editedProject))
            const p = projects[editedProject]
            if (p) {
                setName(p.name)
                setDescription(p.description)
                setCompany(p.company)
            }
        }
        // eslint-disable-next-line
    }, [editedProject])

    useEffect(() => {
        setTabId(projectDialogTabId)
    }, [projectDialogTabId])

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleSubmit = () => {
        setNameError("")
        // create new
        if (!editedProject) {
            if (name && company) {
                dispatch(createProject(company, name))
            } else {
                setNameError(cs.project_form_input_name_error)
            }
        }
        // edit
        else {

        }
    };

    const handleClose = () => {
        dispatch(closeProjectDialog())
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabId(newValue);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    };

    const handleStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreet(event.target.value);
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    };

    const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZip(event.target.value);
    };

    const handleImage = () => {

    };

    const handlePlan = () => {

    };

    const handleMapPosition = () => {

    };

    const handleAddUser = () => {
        setUserDialogOpened(true)
    };

    const handleDeleteUser = (userId: string) => {
        dispatch(removeProjectUser(editedProject, userId))
    };

    const handleUserSubmit = (data: any) => {
        let {user, role} = data;

        dispatch(addProjectUser(editedProject, {
            user: user.uuid,
            rules: getProjectRulesForRole(role)
        }))
        setUserDialogOpened(false)
    };

    const handleAddLabel = () => {
        if (newLabelText !== "") {
            dispatch(createLabel(editedProject, {
                color: newLabelColor,
                name: newLabelText
            }))
            setNewLabelText("")
            setNewLabelColor("")
        }
    };

    const handleDeleteLabel = () => {
        if (labelToBeDeleted) {
            dispatch(deleteLabel(editedProject, labelToBeDeleted))
            setLabelToBeDeleted(null)
        }
    };

    const createDeleteLabelText = () : string => {
        let labelText = labelToBeDeleted && labels[labelToBeDeleted] ? (" " + labels[labelToBeDeleted].name + "?") : ""
        return cs.project_form_delete_label_text + labelText
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    let userIds = projects && editedProject && projects[editedProject].userIds ? projects[editedProject].userIds : []

    return (
        <Dialog
            fullWidth={true}
            maxWidth={editedProject ? "md" : "sm"}
            open={projectDialogOpen}
            // do not close dialog when click away to prevent losing filled data
            onClose={() => {
            }}
            aria-labelledby="project-dialog"
            className={editedProject ? classes.wrapperEdited : classes.wrapper}
        >
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar style={{padding: 0}}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {!editedProject ? <AddIcon size={30}/> : <EditIcon size={30}/>}
                        <span
                            style={{marginLeft: 10}}>{editedProject ? cs.project_form_title_edit : cs.project_form_title_new}</span>
                    </Typography>
                    <div className={classes.grow}/>
                    <div>
                        <IconButton
                            edge="start"
                            onClick={handleClose}
                            color="inherit"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
                {editedProject ?
                    <Tabs value={tabId} onChange={handleTabChange} scrollButtons="off">
                        <Tab icon={<InfoIcon/>} value={0} label={cs.project_form_tab_info} {...tabProps(0)}
                             className={classes.tab}/>
                        <Tab icon={<UsersIcon/>} value={1} label={cs.project_form_tab_users} {...tabProps(1)}
                             className={classes.tab}/>
                        <Tab icon={<LabelsIcon/>} value={2} label={cs.project_form_tab_labels} {...tabProps(2)}
                             className={classes.tab}/>
                    </Tabs> : null
                }
            </AppBar>

            { !editedProject ?
                <DialogContent>
                    <Grid container style={{marginTop: 20}}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="project-name"
                                label={nameError || cs.project_form_input_name}
                                value={name}
                                error={nameError !== ""}
                                variant="outlined"
                                onChange={handleNameChange}
                                className={classes.input}
                            />
                            <TextField
                                id="project-company"
                                select
                                required
                                label={cs.project_form_input_company}
                                value={company}
                                onChange={handleCompanyChange}
                                // helperText="Please select your currency"
                                variant="outlined"
                                className={classes.input}
                            >
                                {companyIds ? companyIds.map((id: string) => companies[id]).map((company: any) => (
                                    <MenuItem key={company.uuid} value={company.uuid}>
                                        {company.name}
                                    </MenuItem>
                                )) : null}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                :
                <DialogContent>
                    {/* --------------------------------------------------------------------------------*/}
                    {/* -- Project info panel */}
                    {/* --------------------------------------------------------------------------------*/}
                    <TabPanel
                        // @ts-ignore
                        value={tabId} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    id="project-name"
                                    label={cs.project_form_input_name}
                                    value={name}
                                    variant="outlined"
                                    onChange={handleNameChange}
                                    className={classes.input}
                                />
                                <TextField
                                    id="project-description"
                                    label={cs.project_form_input_description}
                                    multiline
                                    rows={4}
                                    value={description}
                                    variant="outlined"
                                    onChange={handleDescriptionChange}
                                    className={classes.input}
                                />
                                <TextField
                                    id="project-company"
                                    select
                                    required
                                    label={cs.project_form_input_company}
                                    value={company}
                                    onChange={handleCompanyChange}
                                    // helperText="Please select your currency"
                                    variant="outlined"
                                    className={classes.input}
                                >
                                    {companyIds ? companyIds.map((id: string) => companies[id]).map((company: any) => (
                                        <MenuItem key={company.uuid} value={company.uuid}>
                                            {company.name}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <CardIconButton
                                    onClick={handleImage}
                                    text={cs.project_form_input_image}
                                    icon={<ImageIcon color="#3c4858" size={42}/>}
                                    style={{
                                        height: IMAGE_BUTTON_HEIGHT - 3,
                                        border: "1px dashed #939393",
                                        margin: "0px 0px 10px 0px",
                                    }}
                                    fontStyle={{
                                        fontSize: 11,
                                        color: "#3c4858",
                                        fontWeight: "bold"
                                    }}
                                />
                                <CardIconButton
                                    onClick={handlePlan}
                                    text={cs.project_form_input_plan}
                                    icon={<PlanIcon color="#3c4858" size={42}/>}
                                    style={{
                                        height: IMAGE_BUTTON_HEIGHT - 3,
                                        border: "1px dashed #939393",
                                        margin: 0
                                    }}
                                    fontStyle={{
                                        fontSize: 11,
                                        color: "#3c4858",
                                        fontWeight: "bold"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.addressTitle}>{cs.project_form_input_address}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    id="project-street"
                                    label={cs.project_form_input_street}
                                    value={street}
                                    variant="outlined"
                                    onChange={handleStreetChange}
                                    className={classes.input}
                                />
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        <TextField
                                            required
                                            id="project-city"
                                            label={cs.project_form_input_city}
                                            value={city}
                                            variant="outlined"
                                            onChange={handleCityChange}
                                            className={classes.input}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            id="project-zip"
                                            label={cs.project_form_input_zip}
                                            value={zip}
                                            variant="outlined"
                                            onChange={handleZipChange}
                                            className={classes.input}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <CardIconButton
                                    onClick={handleMapPosition}
                                    text={cs.project_form_input_map_position}
                                    icon={<PinIcon color="#3c4858" size={42}/>}
                                    style={{
                                        height: IMAGE_BUTTON_HEIGHT,
                                        margin: 0
                                    }}
                                    fontStyle={{
                                        fontSize: 11,
                                        color: "#3c4858",
                                        fontWeight: "bold"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* --------------------------------------------------------------------------------*/}
                    {/* -- Project users panel */}
                    {/* --------------------------------------------------------------------------------*/}
                    <TabPanel
                        // @ts-ignore
                        value={tabId} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button onClick={handleAddUser} variant="contained" color="primary">
                                    {cs.project_form_add_user}
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{background: "#f4f6f8"}}>
                                <Grid container>
                                    <Grid item xs={1}/>
                                    <Grid item xs={6}>
                                        {cs.project_form_user_name}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {cs.project_form_user_rights}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {cs.project_form_user_last_activity}
                                    </Grid>
                                    <Grid item xs={1}/>
                                </Grid>
                            </Grid>
                            { userIds.map((userId: string) => {

                                let user = projectUsers[userId];

                                if (!user || user.entityFlag !== "Active") return null;

                                let role = user.rules ? determineProjectRole(user.rules) : null

                                return (
                                    <Grid item xs={12} key={user.user.uuid}>
                                        <Grid container style={{alignItems: "center"}}>
                                            <Grid item xs={1}>
                                                <span className={classes.circle}/>
                                            </Grid>
                                            <Grid item xs={6} style={{fontWeight: "bold"}}>
                                                {user.user ? (user.user.name + " " + user.user.surname) : ""}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {role ? role.roleName : ""}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {user.user ? moment(user.user.lastActivity).format('DD.MM.YY') : ""}
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    edge="start"
                                                    onClick={() => {
                                                        handleDeleteUser(user.user.uuid)
                                                    }}
                                                    color="inherit"
                                                >
                                                    <TrashIcon variant="primary"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    {/* --------------------------------------------------------------------------------*/}
                    {/* -- Project labels panel */}
                    {/* --------------------------------------------------------------------------------*/}
                    <TabPanel
                        // @ts-ignore
                        value={tabId} index={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="new-label-text"
                                    label={cs.project_form_input_label}
                                    value={newLabelText}
                                    variant="outlined"
                                    aria-describedby="color-picker"
                                    onChange={(event) => {
                                        setNewLabelText(event.target.value)
                                    }}
                                    className={classes.input}
                                    inputProps={{
                                        style: {
                                            color: newLabelColor ? theme.palette.getContrastText(newLabelColor) : "black"
                                        }
                                    }}
                                    style={{
                                        borderRadius: 4,
                                        background: newLabelColor || "white",
                                    }}

                                />

                                    <TwitterPicker
                                        onChangeComplete={(color) => {
                                            setNewLabelColor(color.hex)
                                        }}
                                        onChange={(color) => {
                                            setNewLabelColor(color.hex)
                                        }}
                                    />

                            </Grid>
                            <Grid item xs={12} style={{textAlign: "right"}}>
                                <Button onClick={handleAddLabel} variant="contained" color="primary">
                                    {cs.project_form_add_label}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.labelsWrapper}>
                                    {editedProject && projects[editedProject].labelIds && projects[editedProject].labelIds.length > 0 ?
                                        projects[editedProject].labelIds.map((id: string) => labels[id]).map((label: any) =>
                                            <Chip key={label.uuid}
                                                  label={label.name}
                                                  onDelete={() => {
                                                      setLabelToBeDeleted(label.uuid)
                                                  }}
                                                  className={classes.label}
                                                  style={{
                                                      color: theme.palette.getContrastText(label.color),
                                                      background: label.color,
                                                  }}
                                            />) : <span>{cs.project_form_no_labels}</span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <UserDialog
                        user={null}
                        companyId={company}
                        ignored={userIds.map((id: string) => projectUsers[id])
                            .filter((user: any) => user.entityFlag === "Active")
                            .map((user: any) => user.user.uuid)
                        }
                        opened={userDialogOpened}
                        onSubmit={handleUserSubmit}
                        onClose={() => {
                            setUserDialogOpened(false)
                        }}
                    />
                    <ConfirmDialog
                        title={createDeleteLabelText()}
                        opened={labelToBeDeleted !== null}
                        positiveButtonText={cs.general_action_archive}
                        onNegative={() => { setLabelToBeDeleted(null) }}
                        onPositive={() => { handleDeleteLabel() }}
                    />
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    {editedProject ? cs.project_form_edit : cs.project_form_create}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

