import {CALL_API, METHOD_POST} from "../../../middleware/api";


// --------------------------------------------------------
// ---- Authenticate
// --------------------------------------------------------

export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const authenticationRequest = (username: String, password: String) => ({
    [CALL_API]: {
        types: [AUTHENTICATION_REQUEST, AUTHENTICATION_SUCCESS, AUTHENTICATION_FAILURE],
        endpoint: `oauth/token`,
        params: {"grant_type": "password", "password": password, "username": username},
        method: METHOD_POST,
        authorized: false,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const authenticate = (username: String, password: String) => (dispatch: any) => {
    return dispatch(authenticationRequest(username, password))
};