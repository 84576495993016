import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function StateEye(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M13.682 7.441a.26.26 0 0 1 .029.117q0 .059-.029.059l-.117.146q-.645.791-1.157 1.333a17.4 17.4 0 0 1-1.318 1.23 6.208 6.208 0 0 1-1.715 1.056 4.97 4.97 0 0 1-1.875.366 5.094 5.094 0 0 1-2.4-.6 7.236 7.236 0 0 1-1.819-1.29q-.674-.688-1.787-2.036l-.176-.205a.146.146 0 0 1 0-.234 19.13 19.13 0 0 1 3.193-3.164A4.985 4.985 0 0 1 7.5 3.193q3.135 0 6.182 4.248zm-.732.117q-2.725-3.75-5.449-3.75a4.4 4.4 0 0 0-2.637.894A16.605 16.605 0 0 0 2.051 7.5l.088.059q.82 1 1.23 1.436T4.5 10.063a4.387 4.387 0 0 0 1.436.879 4.753 4.753 0 0 0 1.564.249 4.632 4.632 0 0 0 1.1-.131 3.528 3.528 0 0 0 1.113-.513q.571-.381.879-.571a5.687 5.687 0 0 0 .923-.835q.615-.645.718-.762zM12.8 12.8A7.226 7.226 0 0 1 7.5 15a7.226 7.226 0 0 1-5.3-2.2A7.226 7.226 0 0 1 0 7.5a7.226 7.226 0 0 1 2.2-5.3A7.226 7.226 0 0 1 7.5 0a7.226 7.226 0 0 1 5.3 2.2A7.226 7.226 0 0 1 15 7.5a7.226 7.226 0 0 1-2.2 5.3zM7.5.615a6.64 6.64 0 0 0-4.878 2.007A6.64 6.64 0 0 0 .615 7.5a6.606 6.606 0 0 0 2.036 4.863A6.645 6.645 0 0 0 7.5 14.385a6.621 6.621 0 0 0 4.849-2.036A6.621 6.621 0 0 0 14.385 7.5a6.645 6.645 0 0 0-2.021-4.849A6.606 6.606 0 0 0 7.5.615zm0 4.57a2.233 2.233 0 0 1 1.641.674A2.233 2.233 0 0 1 9.814 7.5a2.223 2.223 0 0 1-.688 1.626 2.223 2.223 0 0 1-1.626.688 2.223 2.223 0 0 1-1.626-.688A2.223 2.223 0 0 1 5.186 7.5a2.223 2.223 0 0 1 .688-1.626A2.223 2.223 0 0 1 7.5 5.186zm-1.289 3.6a1.753 1.753 0 0 0 1.289.531 1.753 1.753 0 0 0 1.289-.527A1.753 1.753 0 0 0 9.316 7.5a1.753 1.753 0 0 0-.527-1.289A1.753 1.753 0 0 0 7.5 5.684a1.753 1.753 0 0 0-1.289.527A1.753 1.753 0 0 0 5.684 7.5a1.753 1.753 0 0 0 .527 1.289z"/>
    </svg>

}