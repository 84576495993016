import React, {useEffect} from 'react';
import {
    CircularProgress,
    Grid, IconButton,
    Typography,
    Button
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {loadProjects} from "../actions";
import {idsToObjects} from "../../../middleware/schema/schemaUtil";
import ProjectItem from "./ProjectItem";
import {push} from "connected-react-router";
import routes from "../../../model/routes";
import EditIcon from "../../core/components/icons/Edit";


// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
    title: {
        textAlign: "left",
        marginLeft: 15
    },
}));

/**
 * Wrapper of snackbar component
 */
export default function CompanyItem(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const dispatch = useDispatch();

    const {
        projects,
    } = // @ts-ignore
        useSelector(state => state.claimit.project);

    useEffect(() => {
        if (props.company.uuid) {
            dispatch(loadProjects(props.company.uuid, 0))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleSettings = () => {

    };

    const handleLoadMore = () => {
          dispatch(push(routes.CompanyProjects.createRoute(props.company.uuid)))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <div>
            <Typography variant="h5" className={classes.title}>
                {props.company.name}
                <IconButton
                    onClick={handleSettings}
                    color="primary"
                >
                    <EditIcon variant="primary" />
                </IconButton>
            </Typography>
            {props.company.projectIds ?
                <Grid container>
                    {idsToObjects(props.company.projectIds.slice(0, 10), projects).map(p =>
                        <Grid item key={p.uuid}>
                            <ProjectItem project={p}/>
                        </Grid>
                    )}
                </Grid> : <CircularProgress size={30}/>
            }
            {props.company.projectPage &&
                ((props.company.projectPage.number + 1) !== props.company.projectPage.totalPages)
            ? <Button
                variant="contained"
                onClick={handleLoadMore}
                >
                Show all!
            </Button> : null}
        </div>
    );
}

