import React, {useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    AppBar,
    Toolbar,
    Typography, IconButton, Grid, TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import cs from "../../../localization/cs";
import AddIcon from "../../core/components/icons/New";
import {closeIssueDialog} from "../actions";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    wrapper: {
        "& div.MuiPaper-root": {
            height: "85%"
        }
    },
    appBar: {
        borderRadius: "4px 4px 0px 0px",
        background: "#0f5c98"
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        marginLeft: 20,
        display: "flex",
        alignItems: "center"
    },
    tab: {
        minWidth: 120,
        minHeight: 48,
        paddingTop: 0,
        "& span": {
            flexDirection: "row"
        },
        "& span > *:first-child": {
            marginBottom: "0px !important",
            marginRight: 6
        }
    },
    input: {
        width: "100%",
        marginBottom: 20,
    },
    addressTitle: {
        fontSize: 13,
        color: "#5a5a5a",
        marginLeft: 10,
        marginTop: 24
    },
    circle: {
        display: "block",
        width: 30,
        height: 30,
        background: "#c1deff",
        borderRadius: 15
    },
    labelsWrapper: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 10,
        border: "1px solid #707070",
        borderRadius: 6
    },
    label: {
        margin: 6
    }
}));


/**
 * Wrapper of snackbar component
 */
export default function IssueDialog() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const {
        issueDialogOpen
    }
        // @ts-ignore
        = useSelector(state => state.claimit.issue);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");


    const dispatch = useDispatch();



    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleClose = () => {
        dispatch(closeIssueDialog())
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };


    // const handleDeleteLabel = () => {
    //     if (labelToBeDeleted) {
    //         dispatch(deleteLabel(editedProject, labelToBeDeleted))
    //     }
    // };
    //
    // const createDeleteLabelText = (): string => {
    //     let labelText = labelToBeDeleted && labels[labelToBeDeleted] ? (" " + labels[labelToBeDeleted].name + "?") : ""
    //     return cs.project_form_delete_label_text + labelText
    // };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={issueDialogOpen}
            // do not close dialog when click away to prevent losing filled data
            onClose={() => {
                handleClose()
            }}
            aria-labelledby="project-dialog"
            className={classes.wrapper}
        >
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar style={{padding: 0}}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        <AddIcon size={30}/>
                        <span
                            style={{marginLeft: 10}}>{cs.issue_form_title}</span>
                    </Typography>
                    <div className={classes.grow}/>
                    <div>
                        <IconButton
                            edge="start"
                            onClick={handleClose}
                            color="inherit"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            <DialogContent>

                {/* --------------------------------------------------------------------------------*/}
                {/* -- Project info panel */}
                {/* --------------------------------------------------------------------------------*/}

                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField
                            required
                            id="project-name"
                            label={cs.project_form_input_name}
                            value={name}
                            variant="outlined"
                            onChange={handleNameChange}
                            className={classes.input}
                        />
                        <TextField
                            id="project-description"
                            label={cs.project_form_input_description}
                            multiline
                            rows={4}
                            value={description}
                            variant="outlined"
                            onChange={handleDescriptionChange}
                            className={classes.input}
                        />
                        {/*<TextField*/}
                        {/*    id="project-company"*/}
                        {/*    select*/}
                        {/*    required*/}
                        {/*    label={cs.project_form_input_company}*/}
                        {/*    value={company}*/}
                        {/*    onChange={handleCompanyChange}*/}
                        {/*    // helperText="Please select your currency"*/}
                        {/*    variant="outlined"*/}
                        {/*    className={classes.input}*/}
                        {/*>*/}
                        {/*    {companyIds ? companyIds.map((id: string) => companies[id]).map((company: any) => (*/}
                        {/*        <MenuItem key={company.uuid} value={company.uuid}>*/}
                        {/*            {company.name}*/}
                        {/*        </MenuItem>*/}
                        {/*    )) : null}*/}
                        {/*</TextField>*/}
                    </Grid>
                    <Grid item xs={7}>
                        <span>MAP!!</span>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary">
                    {cs.issue_form_create}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

