import {
    CLOSE_PROJECT_DIALOG,
    CURRENT_ISSUE_CHANGE,
    CURRENT_PART_CHANGE,
    DATA_TAB_CHANGE,
    FILTER_ASSIGNEES_CHANGE, FILTER_LABELS_CHANGE,
    FILTER_PRIORITY_DUE_CHANGE, FILTER_STATES_CHANGE, FILTER_SUPPLIERS_CHANGE, OPEN_PROJECT_DIALOG,
    PROJECT_FAILURE,
    PROJECT_REQUEST,
    PROJECT_SUCCESS
} from "../actions";
import {insertItem, insertItems, removeItem} from "../../../util/array";
import {CREATE_PROJECT_SUCCESS, PROJECTS_SUCCESS} from "../../company/actions";
import {LABELS_CREATE_SUCCESS, LABELS_DELETE_SUCCESS, LABELS_SUCCESS} from "../../label/actions";
import {
    PROJECT_USERS_ADD_SUCCESS,
    PROJECT_USERS_FAILURE, PROJECT_USERS_REMOVE_SUCCESS,
    PROJECT_USERS_REQUEST,
    PROJECT_USERS_SUCCESS
} from "../../user/actions";


export const PARTS_TAB_ID = 0;
export const ISSUES_TAB_ID = 1;
export const PLAN_TAB_ID = 2;

const initialState = {
    projects: {},
    projectIds: [],
    projectLoading: false,
    projectDialogOpen: false,
    projectDialogCompany: null,
    projectDialogTabId: 0,
    editedProject: null,
    dataTabId: PARTS_TAB_ID,
    detailTabId: 0,
    currentPart: null,
    currentIssue: null,
    filter: {
        priorityDue: [],
        states: [],
        labels: [],
        assignees: [],
        suppliers: []
    }
};

const changeFilterState = (prevState: any, filterName: string, value: any) => {
    return {
        ...prevState,
        filter: {
            ...prevState.filter,
            [filterName]: value
        }
    }
};

const insertProjectFromResponse = (state: any, response: any) => {
    return {
        ...state,
        projectLoading: false,
        projects: {
            ...state.projects,
            ...response.entities.project
        },
        projectIds: insertItem(state.projectIds, response.result)
    }
};

const project = (state = initialState, action: any) => {
    switch (action.type) {
        // --------------------------------------------------------
        // ---- PROJECTS
        // --------------------------------------------------------
        case PROJECTS_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    ...action.response.content.entities.project
                },
                projectIds: insertItems(state.projectIds, action.response.content.result)
            };
        // --------------------------------------------------------
        // ---- PROJECT
        // --------------------------------------------------------
        case PROJECT_REQUEST:
            return {
                ...state,
                projectLoading: true
            };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...insertProjectFromResponse(state, action.response),
                editedProject: action.response.result,
                projectDialogCompany: action.parentId,
            }
        case PROJECT_SUCCESS:
            return insertProjectFromResponse(state, action.response)
        case PROJECT_FAILURE:
            return {
                ...state,
                projectLoading: false
            };
        case OPEN_PROJECT_DIALOG:
            return {
                ...state,
                projectDialogOpen: true,
                editedProject: action.editedId,
                projectDialogCompany: action.companyId,
                projectDialogTabId: action.tabId
            }
        case CLOSE_PROJECT_DIALOG:
            return {
                ...state,
                projectDialogOpen: false,
                editedProject: null,
                projectDialogCompany: null,
            }
        // --------------------------------------------------------
        // ---- LABELS
        // --------------------------------------------------------
        case LABELS_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        labelIds: [...action.response.content.result]
                    }
                }
            };
        case LABELS_CREATE_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        // @ts-ignore
                        labelIds: insertItem(state.projects[action.parentId].labelIds || [], action.response.result)
                    }
                }
            };
        case LABELS_DELETE_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId.projectId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId.projectId],
                        // @ts-ignore
                        labelIds: removeItem(state.projects[action.parentId.projectId].labelIds || [],
                            action.parentId.labelId)
                    }
                }
            };
        // --------------------------------------------------------
        // ---- USERS
        // --------------------------------------------------------
        case PROJECT_USERS_REQUEST:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        usersLoading: true
                    }
                }
            };

        case PROJECT_USERS_SUCCESS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        // @ts-ignore
                        userIds: insertItems(state.projects[action.parentId].userIds || [], action.response.content.result),
                        usersLoading: false
                    }
                }
            };

        case PROJECT_USERS_FAILURE:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        usersLoading: false
                    }
                }
            };
        case PROJECT_USERS_ADD_SUCCESS:

            return {
                ...state,
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.projects[action.parentId],
                        // @ts-ignore
                        userIds: insertItem(state.projects[action.parentId].userIds || [], action.response.user),
                    }
                }
            };
        case PROJECT_USERS_REMOVE_SUCCESS:

            // @ts-ignore
            let userIds = state.projects[action.parentId.project].userIds;

            return {
                ...state,
                ...state,
                projects: {
                    ...state.projects,
                    [action.parentId.project]: {
                        // @ts-ignore
                        ...state.projects[action.parentId.project],
                        userIds: userIds ? removeItem(userIds, action.parentId.user) : [],
                    }
                }
            };
        // --------------------------------------------------------
        // ---- TABS
        // --------------------------------------------------------
        case DATA_TAB_CHANGE:
            return {
                ...state,
                dataTabId: action.data
            };
        // --------------------------------------------------------
        // ---- CURRENTS
        // --------------------------------------------------------
        case CURRENT_PART_CHANGE:
            return {
                ...state,
                currentPart: action.data
            };
        case CURRENT_ISSUE_CHANGE:
            return {
                ...state,
                currentIssue: action.data
            };
        // --------------------------------------------------------
        // ---- FILTER
        // --------------------------------------------------------
        case FILTER_PRIORITY_DUE_CHANGE:
            return changeFilterState(state, "priorityDue", action.data);
        case FILTER_STATES_CHANGE:
            return changeFilterState(state, "states", action.data);
        case FILTER_LABELS_CHANGE:
            return changeFilterState(state, "labels", action.data);
        case FILTER_ASSIGNEES_CHANGE:
            return changeFilterState(state, "assignees", action.data);
        case FILTER_SUPPLIERS_CHANGE:
            return changeFilterState(state, "suppliers", action.data);
        default:
            return state
    }
};

export default project
