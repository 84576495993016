import { MapInteractionCSS } from "react-map-interaction";
import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Pin from "../../core/components/icons/Pin";
import PinInvert from "../../core/components/icons/PinInvert";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    pin: {
        position: "absolute",
        cursor: "pointer"
    },
}));

const PIN_SIZE = 30;

export function ImageMap(props) {

    const classes = useStyles();

    const [scale, setScale] = useState(0.4);
    const [activePin, setActivePin] = useState(null);

    const imageRef = useRef(null);

    const handlePinClick = (issueId) => {
        setActivePin(issueId)
        props.onIssueClick(issueId)
    };

    return props.link ? <MapInteractionCSS
        minScale={0.4}
        onChange={(value) => {
            setScale(value.scale)
            console.log("scale: " + value.scale + ", translation: (" + value.translation.x + "," + value.translation.y + ")")
        }}
        scale={scale}
        // translationBounds={{
        //     xMax: scale*(imageSize.width),
        //     xMin: 0,
        //     yMax: scale*(imageSize.height),
        //     yMin: 0
        // }}

    >
        <div className={classes.wrapper}>
            <img ref={imageRef} src={props.link} alt="part-plan" />
            {props.issues ? props.issues.map(issue =>
                <div
                    className={classes.pin}
                    style={{
                        top: issue.planLocation.y - (PIN_SIZE * (1/scale)),
                        left: issue.planLocation.x - ((PIN_SIZE/2) * (1/scale)),
                    }}
                    onClick={() => {handlePinClick(issue.uuid)}}
                >
                    {activePin !== issue.uuid ? <Pin size={PIN_SIZE * (1/scale)} color="#e53935"/> : <PinInvert size={PIN_SIZE * (1/scale)} color="#e53935"/>}
                </div>
            ) : null}
        </div>

    </MapInteractionCSS> : null
}