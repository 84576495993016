import React, {useEffect} from 'react';
import {Toolbar, AppBar, Typography, InputBase, IconButton, Menu, MenuItem, Button} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {fade, makeStyles} from "@material-ui/core/styles";
import cs from "../../../localization/cs";
import {clearSession} from "../../../session/Session";
import {Route, Switch} from "react-router";
import routes from "../../../model/routes";
import {useDispatch, useSelector} from "react-redux";
import {loadCurrentUser} from "../actions";
import classNames from "classnames"
import Companies from "../../company/components/Companies";
import Projects from "../../company/components/Projects";
import {push} from "connected-react-router";
import Project from "../../project/components/Project";
import ArchiveIcon from "../../core/components/icons/Archive";
import LogoIcon from "../../core/components/icons/Logo";
import ProjectDialog from "../../project/components/ProjectDialog";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        paddingTop: 6,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        cursor: "pointer",
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    greyBg: {
        height: window.innerHeight,
        background: "#f4f6f8"
    }
}));


/**
 * Wrapper of snackbar component
 */
export default function Dashboard() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // @ts-ignore
    const user = useSelector(state => state.claimit.dashboard.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCurrentUser());
    }, [dispatch]);

    // --------------------------------------------------------------------------------
    // -- Private constants
    // --------------------------------------------------------------------------------

    const menuId = 'primary-search-account-menu';

    const isMenuOpen = Boolean(anchorEl);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleLogout = () => {
        clearSession();
        window.location.reload()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettings = () => {
        // TODO
    };

    // const handleProjects = () => {
    //     // TODO
    // };

    const handleLogoClick = () => {
        dispatch(push(routes.Dashboard.route))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <div className={classNames(classes.grow, classes.greyBg)}>
            <AppBar position="static">
                <Toolbar style={{padding: 0}}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={cs.dashboard_search}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <div className={classes.grow} />
                    <Typography className={classes.title} variant="h6" noWrap onClick={handleLogoClick}>
                        <LogoIcon size={30}/>
                    </Typography>
                    <div className={classes.grow} />
                    <div>
                        <IconButton
                            edge="start"
                            onClick={handleSettings}
                            color="inherit"
                        >
                            <ArchiveIcon variant="secondary" />
                        </IconButton>
                        <Button
                            variant="text"
                            color="inherit"
                            size="large"
                            className={classes.button}
                            onClick={handleProfileMenuOpen}
                            startIcon={<AccountCircleIcon/>}
                        >
                            {user ? (user.name + " " + user.surname) : "..."}
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                id={menuId}
                keepMounted
                anchorOrigin={{vertical: 'bottom', horizontal: "center"}}
                open={isMenuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>{cs.dashboard_logout}</MenuItem>
            </Menu>
            <Switch>
                <Route exact path={routes.Dashboard.route}><Companies/></Route>
                <Route path={routes.CompanyProjects.route}><Projects/></Route>
                <Route path={routes.Project.route}><Project/></Route>
            </Switch>
            <ProjectDialog />
        </div>
    );
}

