import {CALL_API, METHOD_GET} from "../../../middleware/api";


// --------------------------------------------------------
// ---- Authenticate
// --------------------------------------------------------

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const currentUserRequest = () => ({
    [CALL_API]: {
        types: [CURRENT_USER_REQUEST, CURRENT_USER_SUCCESS, CURRENT_USER_FAILURE],
        endpoint: `accounts/current`,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const loadCurrentUser = () => (dispatch: any) => {
    return dispatch(currentUserRequest())
};
