import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Paper, Typography} from "@material-ui/core";
import {getIssueState} from "../enums/IssueState";
import moment from "moment";
import {ISSUE_EVENT_COMMENT, ISSUE_EVENT_STATE} from "../enums/IssueEventType";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    stateTitle: {
        fontWeight: "bold",
        fontSize: 12,
        marginLeft: 4,
        marginRight: 4
    },
    dot: {
        width: 6,
        height: 6,
        background: "#3c4858",
        borderRadius: 3,
        marginRight: 4
    },
    stateWrapper: {
        display: "flex",
        alignItems: "center",
        margin: 10
    },
    commentCircle: {
        width: 32,
        height: 32,
        background: theme.palette.primary.main,
        color: "white",
        borderRadius: 16,
        marginRight: 8,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    },
    commentBubble: {
        maxWidth: 220,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 12,
        textAlign: "left"
    },
    commentDate: {
        fontSize: 10
    },
    commentWrapper: {
        display: "flex",
        margin: 10
    },
    commentTitle: {
        fontSize: 12,
        fontWeight: "bold"
    },
    commentText: {
        fontSize: 13,
    },
}));

/**
 * Wrapper of snackbar component
 */
export default function DiscussionItem(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const renderEvent = (event: any) => {
        switch (event.type) {
            case ISSUE_EVENT_STATE:
                return <StateItem event={event} classes={classes}/>
            case ISSUE_EVENT_COMMENT:
                return <CommentItem event={event} classes={classes}/>
        }
        return null
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return renderEvent(props.event)
}

const formatChatDate = (dateStr: string | null): string => {
    return dateStr ? moment(dateStr).format('DD.MM.YYYY HH:mm') : ""
};

function StateItem(props: any) {

    let state = props.event ? getIssueState(props.event.state) : null

    return state ? <div className={props.classes.stateWrapper}>
        {state.icon(15)}
        <span className={props.classes.stateTitle}>{state.title}</span>
        <span className={props.classes.dot}/>
        <span style={{fontSize: 12}}>
            {props.event ? formatChatDate(props.event.createdAt) : ""}
        </span>
    </div> : null
}

function CommentItem(props: any) {
    let event = props.event;
    let message = event && event.text ? event.text : "";

    return event ? <div className={props.classes.commentWrapper}>
        <span className={props.classes.commentCircle}>
            <span>SB</span>
        </span>
        <div style={{textAlign: "right"}}>
            <Paper className={props.classes.commentBubble} style={{background: "#f4f6f8"}}>
                <Typography variant="caption" className={props.classes.commentTitle}>Stanislava Balážová</Typography>
                <p className={props.classes.commentText} style={{margin: 0}}>{message}</p>
            </Paper>
            <Typography variant="caption" className={props.classes.commentDate}>
                {props.event ? formatChatDate(props.event.createdAt) : ""}
            </Typography>
        </div>
    </div> : null
}

