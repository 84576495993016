import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Home2(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.083 14.138">
        <g data-name="Group 45">
            <g data-name="Group 44">
                <path
                    d="M14.83 6.282L8.062.201a.778.778 0 0 0-1.042 0L.253 6.282a.751.751 0 0 0-.2.839.768.768 0 0 0 .721.487h1.081v6.093a.441.441 0 0 0 .444.437h3.709a.441.441 0 0 0 .444-.437v-3.7h2.18v3.7a.441.441 0 0 0 .444.437h3.709a.441.441 0 0 0 .444-.437V7.608h1.081a.768.768 0 0 0 .721-.487.751.751 0 0 0-.201-.839z"
                    data-name="Path 15"/>
            </g>
        </g>
        <g data-name="Group 47">
            <g data-name="Group 46">
                <path d="M2.979 0H0l3.427 3.07V.437A.441.441 0 0 0 2.979 0z" data-name="Path 16"
                      transform="translate(10.133 .873)"/>
            </g>
        </g>
    </svg>
}