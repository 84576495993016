import React from 'react';
import {Grid, Checkbox, Badge} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PartIcon from "../../core/components/icons/Folder";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    row: {
        padding: 10,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #eff0f1",
        cursor: "pointer",
        "&:hover": {
            background: "#eff0f1"
        }
    },
    title: {
        fontWeight: "bold",
        textAlign: "left"
    },
    iconWrapper: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    }
}));

const ICON_SIZE = 32

/**
 * Wrapper of snackbar component
 */
export default function PartRow(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newChecked = event.target.checked;
        console.log(newChecked)

        // TODO handle checkbox
    };

    const handleClick = () => {
        props.onClick(props.part.uuid);
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Grid container className={classes.row} onClick={handleClick}>
            <Grid item xs={1}>
                <Checkbox
                    checked={props.checked}
                    onChange={handleChange}
                    onClick={handleCheckboxClick}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                />
            </Grid>
            <Grid item xs={1} className={classes.iconWrapper}>
                <Badge color="primary"
                       badgeContent={props.part.issuesCount}
                       max={99}
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                       }}
                       overlap="circle"
                >
                    <PartIcon variant="primary" size={ICON_SIZE} />
                </Badge>
            </Grid>
            <Grid item xs={3} className={classes.title}>

                <span>{props.part ? props.part.name : "Název složky"}</span>
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={1}>

            </Grid>
        </Grid>
    );
}

