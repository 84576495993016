import React, {useEffect} from 'react';
import {useTheme, fade, makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {Popper, Chip, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete, {AutocompleteCloseReason} from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import {useDispatch, useSelector} from "react-redux";
import {loadLabels} from "../actions";
import {removeItem} from "../../../util/array";
import cs from "../../../localization/cs";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 13,
            display: "flex",
            alignItems: "center",

            paddingLeft: 6,
        },
        popper: {
            border: '1px solid rgba(27,31,35,.15)',
            boxShadow: '0 3px 12px rgba(27,31,35,.15)',
            borderRadius: 3,
            maxWidth: 300,
            zIndex: 1101,
            fontSize: 13,
            color: '#586069',
            backgroundColor: '#f6f8fa',
        },
        header: {
            borderBottom: '1px solid #e1e4e8',
            padding: '8px 10px',
            fontWeight: 600,
        },
        inputBase: {
            padding: 10,
            width: '100%',
            borderBottom: '1px solid #dfe2e5',
            '& input': {
                borderRadius: 4,
                backgroundColor: theme.palette.common.white,
                padding: 8,
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                border: '1px solid #ced4da',
                fontSize: 14,
                '&:focus': {
                    boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        paper: {
            boxShadow: 'none',
            margin: 0,
            color: '#586069',
            fontSize: 13,
        },
        option: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"]': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        popperDisablePortal: {
            position: 'relative',
        },
        iconSelected: {
            width: 17,
            height: 17,
            marginRight: 5,
            marginLeft: -2,
        },
        color: {
            width: 14,
            height: 14,
            flexShrink: 0,
            borderRadius: 3,
            marginRight: 8,
            marginTop: 2,
        },
        text: {
            flexGrow: 1,
        },
        close: {
            opacity: 0.6,
            width: 18,
            height: 18,
        },
        title: {
            cursor: "pointer",
            paddingTop: 10,
            paddingBottom: 9
        }
    }),
);

export default function LabelsInput(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // local states
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [pendingValue, setPendingValue] = React.useState<string[]>([]);

    // redux states
    const {
        labels,
        labelsLoading
    } = // @ts-ignore
        useSelector(state => state.claimit.label);

    const theme = useTheme();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!labelsLoading && props.project && !props.project.labelIds) {
            dispatch(loadLabels(props.project.uuid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setPendingValue(props.selected);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        if (reason === 'toggleInput') {
            return;
        }
        props.onChange(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const handleDelete = (uuid: string) => {
        props.onChange(removeItem(props.selected, uuid))
    };

    const handleDeleteAll = () => {
        props.onChange([])
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <div className={classes.root} style={{maxWidth: (props.maxWidth ? props.maxWidth : "initial") }}>
                {!props.noTitle ? <span className={classes.title} onClick={handleClick}>
                    {props.selected && props.selected.length > 0 ? (cs.filter_labels + ": ") : cs.filter_labels_title}
                </span> : null}

                <div>
                    {props.selected ? // @ts-ignore
                        props.selected.map(id => labels[id]).map((label) => label ? (
                            <Chip key={label.uuid} label={label.name} onDelete={() => {handleDelete(label.uuid)}} style={{
                                backgroundColor: label.color,
                                color: theme.palette.getContrastText(label.color),
                                margin: "4px 0px 4px 4px"
                            }} size="small"/>
                        ) : null) : null}
                </div>

                {!props.singleClear && props.selected && props.selected.length > 0 ? <IconButton
                    size="small"
                    onClick={handleDeleteAll}
                    disableRipple
                >
                    <DeleteIcon/>
                </IconButton> : null}


                <IconButton
                    size="small"
                    onClick={handleClick}
                    disableRipple
                >
                    {props.icon ? props.icon : <DropDownIcon/>}
                </IconButton>
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                className={classes.popper}
            >
                <div className={classes.header}>{cs.filter_labels_desc}</div>
                <Autocomplete
                    open
                    onClose={handleClose}
                    multiple
                    classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingValue}
                    onChange={(event, newValue) => {
                        setPendingValue(newValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText={cs.filter_labels_none}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <DoneIcon
                                className={classes.iconSelected}
                                style={{visibility: selected ? 'visible' : 'hidden'}}
                            />
                            <span className={classes.color} style={{backgroundColor: labels[option].color}}/>
                            <div className={classes.text}>
                                {labels[option].name}
                            </div>
                            <CloseIcon
                                className={classes.close}
                                style={{visibility: selected ? 'visible' : 'hidden'}}
                            />
                        </React.Fragment>
                    )}
                    options={props.project.labelIds ? [...props.project.labelIds].sort((a, b) => {
                        // Display the selected labels first.
                        let ai = props.selected.indexOf(a);
                        ai = ai === -1 ? props.selected.length + props.project.labelIds.indexOf(a) : ai;
                        let bi = props.selected.indexOf(b);
                        bi = bi === -1 ? props.selected.length + props.project.labelIds.indexOf(b) : bi;
                        return ai - bi;
                    }) : []}
                    getOptionLabel={(option) => labels[option].name}
                    renderInput={(params) => (
                        <InputBase
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus
                            className={classes.inputBase}
                        />
                    )}
                />
            </Popper>
        </React.Fragment>
    );
}

