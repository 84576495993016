import {normalize, Schema} from 'normalizr'
//import { camelizeKeys } from 'humps'
import fetch from 'isomorphic-fetch'
import {clearSession, createSession, getAccessToken, getRefreshToken} from "../session/Session";
import {push} from "connected-react-router";
import routes from "../model/routes";

export const API_ROOT = 'https://api.managit.cz/v1/';
export const API_AUTHORIZATION = 'Basic ' + btoa('web-js:T4Q7wsX39A');

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PATCH = 'PATCH';
export const METHOD_DELETE = 'DELETE';

const transformUrl = (endpoint: string, params: any, authorized: boolean): string => {
    let fullUrl = (API_ROOT + endpoint) + ((params || authorized) ? "?" : "");

    // fullUrl = fullUrl + '?access_token=' + Session.getAccessToken();

    // add all params to url
    if (params) {
        // eslint-disable-next-line no-unused-vars
        for (let paramKey in params) {
            fullUrl = fullUrl + "&" + paramKey + "=" + params[paramKey]
        }
    }

    if (authorized) {
        fullUrl = fullUrl + "&access_token=" + getAccessToken()
    }

    return fullUrl
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint: string, schema: Schema, method: string, body: any, params: any, authorized: boolean, paged: boolean) => {

    const fullUrl = transformUrl(endpoint, params, authorized)

    return fetch(fullUrl, {
        method: (method ? method : METHOD_GET),
        body: JSON.stringify(body),
        headers: {
            'Authorization': API_AUTHORIZATION,
            'Content-Type': 'application/hal+json',
            'Accept': 'application/hal+json'
        }
    })
        .then(response => {

                if (!response.ok) {
                    // if there is an error, pass the status together with json object
                    return Promise.reject({status: response.status});
                }

                return response.json().then(json => {
                    let finalJson = json;

                    if (schema) {
                        finalJson = normalize(paged ? json.content : json, schema)
                    }

                    if (paged) {
                        return {
                            content: finalJson,
                            page: json.page
                        }
                    } else {
                        return finalJson;
                    }
                })
            }
        )
};

export const callSimpleRequest = (endpoint: string, method: string, body: any, params: any, authorized: boolean) => {
    const fullUrl = transformUrl(endpoint, params, authorized)

    return fetch(fullUrl, {
        method: (method ? method : METHOD_GET),
        body: JSON.stringify(body),
        headers: {
            'Authorization': API_AUTHORIZATION,
            'Content-Type': 'application/hal+json',
            'Accept': 'application/hal+json'
        }
    })
        .then(response => {
            if (!response.ok) {
                // if there is an error, pass the status together with json object
                return Promise.reject({status: response.status});
            }
            return response.json()
        })
}


// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store: { getState: () => any; }) => (next: (arg0: any) => void) => (action: { [x: string]: any; }) => {
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    let {endpoint} = callAPI;
    const {schema, types, method, body, params, parentId, authorized, paged} = callAPI;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState())
    }

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.')
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three actions types.')
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected actions types to be strings.')
    }

    const actionWith = (data: { type: any; response?: { result: any; }; parentId?: any; error?: any; }) => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction
    };

    const [requestType, successType, failureType] = types;
    next(actionWith({type: requestType, parentId}));

    return callApi(endpoint, schema, method, body, params, authorized !== false, paged !== false).then(
        response =>
            next(actionWith({
                response,
                parentId,
                type: successType
            })),
        error => {

            switch (error.status) {
                case 400:
                case 401:
                    // refreshes token
                    callSimpleRequest(`oauth/token`,
                        METHOD_POST,
                        {},
                    {"grant_type": "password", "refresh_token": getRefreshToken()},
                        false).then(
                            response => {
                                // restores session and reloads current page
                                createSession(response)
                                window.location.reload()
                            },
                            error => {
                                // refresh token failure -> clears session and redirects to login
                                clearSession()
                                next(push(routes.Authentication.route))
                            }
                    )
                    break;
                case 404:
                case 409:
                case 500:
                    next(actionWith({
                        type: failureType,
                        error: error.message || 'Something bad happened'
                    }));
                    break;
                default:
                    next(actionWith({
                        type: failureType,
                        error: error.message || 'Something bad happened'
                    }))
            }
        }
    )
}
