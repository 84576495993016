import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function New(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.854 14.854">
        <path
            d="M7.441 14.854A7.182 7.182 0 0 1 2.2 12.671 7.11 7.11 0 0 1 0 7.441a7.158 7.158 0 0 1 2.2-5.246 7.158 7.158 0 0 1 5.244-2.2 7.11 7.11 0 0 1 5.229 2.2 7.182 7.182 0 0 1 2.183 5.244 7.168 7.168 0 0 1-2.156 5.247 7.13 7.13 0 0 1-5.259 2.168zm4.717-12.159A6.369 6.369 0 0 0 7.441.732 6.466 6.466 0 0 0 2.7 2.695 6.466 6.466 0 0 0 .732 7.441 6.466 6.466 0 0 0 2.7 12.188a6.466 6.466 0 0 0 4.741 1.962 6.417 6.417 0 0 0 4.731-1.963 6.49 6.49 0 0 0 1.948-4.746 6.466 6.466 0 0 0-1.962-4.746zM8 6.943h3.076a.311.311 0 0 1 .352.352.311.311 0 0 1-.352.352H7.939v3.076q0 .352-.381.352a.311.311 0 0 1-.352-.352V7.647H4.16q-.381 0-.381-.352t.381-.352h3.135V3.867a.311.311 0 0 1 .352-.352.311.311 0 0 1 .353.352z"/>
    </svg>
}