
const USERNAME_TAG = "c-user-name";
const ACCESS_TOKEN_TAG = "c-access-token";
const REFRESH_TOKEN_TAG = "c-reset-token";
export const REDIRECT_URI_TAG = "c-redirect-uri";

export function createSession(authObject: any) {
    sessionStorage.setItem(ACCESS_TOKEN_TAG, authObject.access_token);
    sessionStorage.setItem(REFRESH_TOKEN_TAG, authObject.refresh_token);
}

export function clearSession() {
    sessionStorage.clear()
}

export function isAuthenticated(): boolean {
    return getAccessToken() != null
}

export function setUser(username: string) {
    localStorage.setItem(USERNAME_TAG, username)
}

export function getUserName() {
    return localStorage.getItem(USERNAME_TAG)
}

export function getAccessToken() {
    return sessionStorage.getItem(ACCESS_TOKEN_TAG)
}

export function getRefreshToken() {
    return sessionStorage.getItem(REFRESH_TOKEN_TAG)
}

export function addTokenToRoute(route: string, asParam: boolean = false): string {
    return route + (!asParam ? "?access_token=" : "&access_token=") + getAccessToken()
}