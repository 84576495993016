import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function StateProgress(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
        <path d="M12.8 12.8A7.226 7.226 0 0 1 7.5 15a7.226 7.226 0 0 1-5.3-2.2A7.226 7.226 0 0 1 0 7.5a7.226 7.226 0 0 1 2.2-5.3A7.226 7.226 0 0 1 7.5 0a7.226 7.226 0 0 1 5.3 2.2A7.226 7.226 0 0 1 15 7.5a7.226 7.226 0 0 1-2.2 5.3zM7.5.615a6.64 6.64 0 0 0-4.878 2.007A6.64 6.64 0 0 0 .615 7.5a6.606 6.606 0 0 0 2.036 4.863A6.645 6.645 0 0 0 7.5 14.385a6.621 6.621 0 0 0 4.849-2.036A6.621 6.621 0 0 0 14.385 7.5a6.645 6.645 0 0 0-2.021-4.849A6.606 6.606 0 0 0 7.5.615zm3.75 13.008l-3.369-3.369a3.755 3.755 0 0 1-2.036.19 3.2 3.2 0 0 1-1.714-.894 3.263 3.263 0 0 1-.937-1.8 3.71 3.71 0 0 1 .293-2.124q.176-.176.264-.176a.435.435 0 0 1 .322.117l1.874 1.874L7.119 7l.439-1.2-1.874-1.874q-.117-.059-.117-.293a1.03 1.03 0 0 0 .1-.146 1.016 1.016 0 0 1 .132-.176 3.786 3.786 0 0 1 2.153-.293 3.219 3.219 0 0 1 1.8.908 3.119 3.119 0 0 1 .879 1.714 3.859 3.859 0 0 1-.205 2.036l3.252 3.252a.337.337 0 0 1 .088.249.337.337 0 0 1-.088.249.323.323 0 0 1-.5 0L9.814 8.057a.487.487 0 0 1-.059-.381 2.61 2.61 0 0 0-.5-3.164 2.716 2.716 0 0 0-2.7-.645l1.619 1.641q.146.293.088.381l-.586 1.494-.176.176-1.494.557a.289.289 0 0 1-.381-.059l-1.553-1.67a2.562 2.562 0 0 0 .615 2.666 2.6 2.6 0 0 0 1.495.747 2.924 2.924 0 0 0 1.7-.249.322.322 0 0 1 .381.088l3.486 3.486a.337.337 0 0 1 .088.249.337.337 0 0 1-.088.249.338.338 0 0 1-.234.117.364.364 0 0 1-.265-.117z"/>
    </svg>

}