import React from 'react';
import {CircularProgress, Grid, Paper, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {ISSUE_EVENT_FILE} from "../enums/IssueEventType";
import moment from "moment";
import {addTokenToRoute} from "../../../session/Session";
import cs from "../../../localization/cs";
import {getIssueState} from "../enums/IssueState";
import LabelsInput from "../../label/components/LabelsInput";
import PlusIcon from "../../core/components/icons/New";
import EditableText from "../../core/components/EditableText";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    mainImage: {
        width: "100%",
        height: 184,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        marginBottom: 20,
        borderRadius: 4
    },
    stateColumn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left"
    },
    stateWrapper: {
        marginLeft: 4,
        color: "#3c4858",
    },
    stateCurrent: {
        fontSize: 11,
        margin: 0
    },
    stateTitle: {
        fontSize: 14,
        margin: 0
    },
    stateButton: {
        maxWidth: "100%",
        fontSize: 11,
        borderRadius: 14
    },
    labelsWrapper: {
        display: "flex",

    }
}));


/**
 * Wrapper of snackbar component
 */
export default function IssueDetails(props: any) {

    const issue = props.issue;

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const {
        events
        //@ts-ignore
    } = useSelector(state => state.claimit.issue)

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    /**
     * Gets file issue event that was created first (min createdAt)
     */
    const getIssueImage = () => {
        if (!issue || !issue.events) return null;

        let files = issue.events.map((id: string) => events[id]).filter((event: any) => event && event.type === ISSUE_EVENT_FILE)
        if (files.length === 0) return null;

        return files.reduce((prev: any, curr: any) => {
            return moment(prev.createdAt).isBefore(moment(curr.createdAt)) ? prev : curr;
        })
    };


    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    let mainImage = getIssueImage();
    let issueState = issue && issue.state ? getIssueState(issue.state.state) : null;

    let nextStates = issueState ? issueState.nextStates(false) : null;

    return (
        props.issue ? <div>

            {mainImage && mainImage.file ? <Paper style={{maxWidth: "100%"}}>
                <div
                    className={classes.mainImage}
                    style={{
                        backgroundImage: "url('" + addTokenToRoute(mainImage.file["_links"].self.href) + "')",
                    }}/>
            </Paper> : null}

            <Grid container>
                <Grid item xs={4} className={classes.stateColumn}>
                    {issueState ? issueState.icon(25, "", "#3c4858") : null}
                    {issueState ? <div className={classes.stateWrapper}>
                        <p className={classes.stateCurrent}>{cs.issue_current_state}</p>
                        <p className={classes.stateTitle}>{issueState ? issueState.title : ""}</p>
                    </div> : null}
                </Grid>
                <Grid item xs={4}>
                    {nextStates && nextStates.left ? <Button variant="contained" color="primary" className={classes.stateButton}>
                        {getIssueState(nextStates.left).title}</Button> : null}
                </Grid>
                <Grid item xs={4}>
                    {nextStates && nextStates.right ? <Button variant="contained" color="primary" className={classes.stateButton}>
                        {getIssueState(nextStates.right).title}</Button> : null}
                </Grid>
            </Grid>

            <EditableText
                text={props.issue.description}
                onChangeComplete={(text: string) => {
                    console.log("changed -> " + text)
                }}
                onChangeCancel={() => {
                    console.log("change cancel")
                }}
                style={{
                    textAlign: "right",
                    marginTop: 20,
                    marginBottom: 20
                }}
                textFieldStyle={{
                    textAlign: "left"
                }}
                rowsMax={5}

            />

            {props.project && props.issue ? <div className={classes.labelsWrapper}>
                <LabelsInput
                    project={props.project}
                    selected={props.issue.labels ? props.issue.labels.map((l: any) => l.uuid) : []}
                    onChange={() => {}}
                    noTitle={true}
                    icon={<PlusIcon variant="primary"/>}
                    singleClear={true}
                />
            </div> : null}
        </div> : <CircularProgress style={{margin: 20}} size={30} key="progress-data"
                                   color="primary"/>
    );
}

