import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Archive(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.158 14.473">
        <path d="M0 13.038V1.524a.078.078 0 0 0 .088-.088A1.485 1.485 0 0 1 1.67 0h8.818a1.615 1.615 0 0 1 1.216.469 1.655 1.655 0 0 1 .454 1.2v11.046a2.4 2.4 0 0 1-.234.967.713.713 0 0 1-.205.308 2.47 2.47 0 0 1-.4.278q-.249.146-.337.205H1.26a7.136 7.136 0 0 1-.615-.41A3.581 3.581 0 0 1 0 13.038zm.7-3.784h10.814v-4.1H.7zm10.811-4.746V1.754q0-1.025-1.084-1.025H1.816A1.036 1.036 0 0 0 .645 1.905v2.607zM.7 9.961v2.607a1.049 1.049 0 0 0 1.2 1.173h8.53a1.036 1.036 0 0 0 1.17-1.172V9.961zm5.364-1.582H5.039a.8.8 0 0 1-.63-.249.917.917 0 0 1-.22-.63q0-.381.322-.381.264 0 .381.322a.781.781 0 0 0 .015.176q.015.059.1.088a.479.479 0 0 0 .117.029h1.9a.4.4 0 0 0 .381-.293.351.351 0 0 1 .117-.278.281.281 0 0 1 .293-.044q.352.146.234.469a.835.835 0 0 1-.879.791zm0-5.127a5.681 5.681 0 0 1-1.172-.088q-.527 0-.7-.7 0-.381.234-.469a.253.253 0 0 1 .278.044.376.376 0 0 1 .1.278q0 .293.322.293h1.817q.322 0 .322-.293a.376.376 0 0 1 .1-.278.253.253 0 0 1 .278-.044q.322 0 .322.469a.835.835 0 0 1-.791.879h-.231a2.416 2.416 0 0 0-.879-.089zm0 9.551H4.893a.709.709 0 0 1-.571-.264.932.932 0 0 1-.222-.615q0-.381.322-.381.381 0 .381.322 0 .293.41.293h1.818a.4.4 0 0 0 .381-.293q0-.322.41-.322.322 0 .322.381a.858.858 0 0 1-.22.557.914.914 0 0 1-.571.322h-.175a2.673 2.673 0 0 1-1.114 0z"/>
    </svg>
}