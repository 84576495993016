import {CALL_API, METHOD_GET} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";


// --------------------------------------------------------
// ---- Load project with id
// --------------------------------------------------------

export const PROJECT_REQUEST = "PROJECT_REQUEST";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";
export const PROJECT_FAILURE = "PROJECT_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const projectRequest = (projectId: string) => ({
    [CALL_API]: {
        types: [PROJECT_REQUEST, PROJECT_SUCCESS, PROJECT_FAILURE],
        endpoint: `projects/` + projectId,
        schema: Schemas.PROJECT,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const loadProject = (projectId: string) => (dispatch: any) => {
    return dispatch(projectRequest(projectId))
};


// --------------------------------------------------------
// ---- Changes current part id
// --------------------------------------------------------

export const CURRENT_PART_CHANGE = "CURRENT_PART_CHANGE";

export const changeCurrentPart = (partId: string | null) => {
    return { type: CURRENT_PART_CHANGE, data: partId }
};

// --------------------------------------------------------
// ---- Changes current issue id
// --------------------------------------------------------

export const CURRENT_ISSUE_CHANGE = "CURRENT_ISSUE_CHANGE";

export const changeCurrentIssue = (issueId: string | null) => {
    return { type: CURRENT_ISSUE_CHANGE, data: issueId }
};

// --------------------------------------------------------
// ---- Changes data tab
// --------------------------------------------------------

export const DATA_TAB_CHANGE = "DATA_TAB_CHANGE";

export const changeDataTab = (tabId: number) => {
    return { type: DATA_TAB_CHANGE, data: tabId }
};

// --------------------------------------------------------
// ---- Changes filter priority
// --------------------------------------------------------

export const FILTER_PRIORITY_DUE_CHANGE = "FILTER_PRIORITY_DUE_CHANGE";

export const changeFilterPriorityDue = (priorityDue: Array<string>) => {
    return { type: FILTER_PRIORITY_DUE_CHANGE, data: priorityDue }
};

// --------------------------------------------------------
// ---- Changes filter issue states
// --------------------------------------------------------

export const FILTER_STATES_CHANGE = "FILTER_STATES_CHANGE";

export const changeFilterStates = (states: Array<number>) => {
    return { type: FILTER_STATES_CHANGE, data: states }
};

// --------------------------------------------------------
// ---- Changes filter issue labels
// --------------------------------------------------------

export const FILTER_LABELS_CHANGE = "FILTER_LABELS_CHANGE";

export const changeFilterLabels = (labels: Array<string>) => {
    return { type: FILTER_LABELS_CHANGE, data: labels }
};

// --------------------------------------------------------
// ---- Changes filter issue assignees
// --------------------------------------------------------

export const FILTER_ASSIGNEES_CHANGE = "FILTER_ASSIGNEES_CHANGE";

export const changeFilterAssignees = (assignees: Array<string>) => {
    return { type: FILTER_ASSIGNEES_CHANGE, data: assignees }
};

// --------------------------------------------------------
// ---- Changes filter issue suppliers
// --------------------------------------------------------

export const FILTER_SUPPLIERS_CHANGE = "FILTER_SUPPLIERS_CHANGE";

export const changeFilterSuppliers = (suppliers: Array<string>) => {
    return { type: FILTER_SUPPLIERS_CHANGE, data: suppliers }
};

// --------------------------------------------------------
// ---- Open project dialog
// --------------------------------------------------------

export const OPEN_PROJECT_DIALOG = "OPEN_PROJECT_DIALOG";

export const openProjectDialog = (companyId: string | null, editedId: string | null = null, tabId: number = 0) => {
    return { type: OPEN_PROJECT_DIALOG, companyId, editedId, tabId }
};

// --------------------------------------------------------
// ---- Close project dialog
// --------------------------------------------------------

export const CLOSE_PROJECT_DIALOG = "CLOSE_PROJECT_DIALOG";

export const closeProjectDialog = () => {
    return { type: CLOSE_PROJECT_DIALOG }
};