import React from 'react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid, IconButton,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DotsIcon from "@material-ui/icons/MoreVert";
import {addTokenToRoute} from "../../../session/Session";
import {useDispatch} from "react-redux";
import routes from "../../../model/routes";
import {push} from "connected-react-router";
import UsersIcon from "../../core/components/icons/Users";
import LabelsIcon from "../../core/components/icons/Labels";
import InfoIcon from "../../core/components/icons/Info";
import ExportIcon from "../../core/components/icons/Export";
import {openProjectDialog} from "../../project/actions";


// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    root: {
        width: 210,
        margin: 15
    },
    media: {
        height: 120,
    },
    title: {
        textAlign: "left",
        marginLeft: 15
    },
    itemTitle: {
        textAlign: "left",
        fontSize: 16,
        marginBottom: 0
    },
    cardContent: {
        height: 68,
        paddingBottom: 8,
        paddingTop: 8
    },
    actionButton: {
        margin: 0
    },
    buttonGridItem: {
        width: "20%"
    }
}));

const iconsColor = "#d8d8d8";

/**
 * Wrapper of snackbar component
 */
export default function ProjectItem(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const dispatch  = useDispatch();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const project = props.project;

    const handleProjectClick = () => {
          dispatch(push(routes.Project.createRoute(project.uuid)))
    };

    const handleUsers = () => {
        dispatch(openProjectDialog(project.company, project.uuid, 1))
    };

    const handleActions = () => {

    };

    const handleExport = () => {

    };

    const handleEdit = () => {

    };

    const handleLabels = () => {
        dispatch(openProjectDialog(project.company, project.uuid, 2))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleProjectClick}>
                <CardMedia
                    className={classes.media}
                    // TODO add placeholder image
                    image={project.image ? addTokenToRoute(project.image._links.self.href) : "/images/lul.jpg"}
                    title={project.name}
                    style={{backgroundColor: "#d8d8d8"}}
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="subtitle1" className={classes.itemTitle}>
                        {project.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Grid container>
                    <Grid item className={classes.buttonGridItem}>
                        <IconButton
                            onClick={handleUsers}
                            color="default"
                            className={classes.actionButton}
                            size="small"
                        >
                            <UsersIcon color={iconsColor}/>
                        </IconButton>
                    </Grid>

                    <Grid item className={classes.buttonGridItem}>
                        <IconButton
                            onClick={handleExport}
                            color="default"
                            className={classes.actionButton}
                            size="small"
                        >
                            <ExportIcon color={iconsColor}/>
                        </IconButton>
                    </Grid>

                    <Grid item className={classes.buttonGridItem}>
                        <IconButton
                            onClick={handleLabels}
                            color="default"
                            className={classes.actionButton}
                            size="small"
                        >
                            <LabelsIcon color={iconsColor}/>
                        </IconButton>
                    </Grid>

                    <Grid item className={classes.buttonGridItem}>
                        <IconButton
                            onClick={handleEdit}
                            color="default"
                            className={classes.actionButton}
                            size="small"
                        >
                            <InfoIcon color={iconsColor}/>
                        </IconButton>
                    </Grid>

                    <Grid item className={classes.buttonGridItem}>
                        <IconButton
                            onClick={handleActions}
                            color="default"
                            className={classes.actionButton}
                            size="small"
                        >
                            <DotsIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

