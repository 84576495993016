import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Arrow from "../../../img/directional.png";
import cs from "../../../localization/cs";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import routes from "../../../model/routes";
import {loadPartBranch} from "../../part/actions";
import HomeIcon from "../../core/components/icons/Home2";



// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const TEXT_COLOR = "#3c4858";

const useStyles = makeStyles(() => ({
    wrapper: {
        textAlign: "left",
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        alignItems: "center"
    },
    arrow: {
        width: 7,
        margin: 8
    },
    homeIcon: {
        cursor: "pointer",
        color: TEXT_COLOR
    },
    breadcrumb: {
        cursor: "pointer",
        color: TEXT_COLOR
    },
    breadcrumbWrapper: {
        display: "flex",
        alignItems: "center"
    }
}));

/**
 * Wrapper of snackbar component
 */
export default function BreadCrumbs(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    // waiting lock
    const [waiting, setWaiting] = useState(false);

    const classes = useStyles();

    // @ts-ignore
    const {currentPart} = useSelector(state => state.claimit.project);

    // @ts-ignore
    const {parts, partBranchLoading} = useSelector(state => state.claimit.part);

    const dispatch = useDispatch();

    // checks every time currentPart is updated
    useEffect(() => {

        // checks if data are loaded
        if (currentPart !== null && parts[currentPart] && !haveAllParts(currentPart)) {
            setWaiting(false)
            dispatch(loadPartBranch(currentPart))
        } else {
            setWaiting(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPart]);

    // checks every time parts are updated
    useEffect(() => {

        if (waiting && currentPart !== null && parts[currentPart] && !haveAllParts(currentPart) && !partBranchLoading) {
            setWaiting(false)
            dispatch(loadPartBranch(currentPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts]);

    // --------------------------------------------------------------------------------
    // -- Private function
    // --------------------------------------------------------------------------------

    function ArrowImage() {
        return (
            <img src={Arrow} className={classes.arrow} alt={cs.breadcrumbs_arrow} title={cs.breadcrumbs_arrow}/>
        )
    }

    const handleHome = () => {
        dispatch(push(routes.Dashboard.route))
    };

    const handlePartClick = (partId: string) => {
        if (partId !== currentPart) {
            dispatch(push(routes.Part.createRoute(props.project.uuid, partId)))
        } else {
            console.log(haveAllParts(currentPart))
        }
    };

    const haveAllParts = (partId: string | null): boolean => {

        if (partId === null) {
            return false;
        }

        let part = parts[partId];
        while(part && part.parent) {
            if (!parts[part.parent] && part.parent !== props.project.mainPart) return false;
            part = parts[part.parent]
        }
        return true
    };

    const getPartsArray = (partId: string | null): Array<any> => {
        if (partId === null) return [];
        let part = parts[partId];
        return part ? [part, ...getPartsArray(part.parent)] : []
    };

    const renderParts = (partId: string | null) => {
        return getPartsArray(partId).reverse().map(part =>
            <span className={classes.breadcrumbWrapper} key={part.uuid}>
                 <ArrowImage/>
                <Typography variant="subtitle2" className={classes.breadcrumb} onClick={() => {handlePartClick(part.uuid)}}>
                {part.name}
                </Typography>
            </span>
        )
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Box my={1} className={classes.wrapper}>
            <Typography variant="subtitle2" className={classes.homeIcon} onClick={handleHome}>
                <HomeIcon variant="primary"/>
            </Typography>
            {props.project ? renderParts(currentPart) : null}
        </Box>
    );
}

