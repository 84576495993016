import React, {useEffect} from 'react';
import {AppBar, Box, IconButton, Paper, Tab, Tabs, Toolbar, Typography} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import MoreIcon from "@material-ui/icons/MoreVert";
import cs from "../../../localization/cs";
import {changeDetailTab, loadIssue, loadIssueEvents} from "../actions";
import {useParams} from "react-router";
import {TabPanelProps} from "@material-ui/lab/TabPanel/TabPanel";
import CircleIcon from "../../core/components/icons/StateNew";
import AttachmentIcon from "../../core/components/icons/Attachment";
import DiscussionIcon from "../../core/components/icons/Message";
import IssueDetails from "./IssueDetails";
import Attachments from "./Attachments";
import Discussion from "./Discussion";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    wrapper: {
        textAlign: "center",
        margin: "10px 20px 10px 10px",
        height: window.innerHeight - 200,
        position: "relative"
    },
    appBar: {
        borderRadius: "4px 4px 0px 0px",
        background: "#0f5c98"
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        marginLeft: 20
    },
    tab: {
        minWidth: 120,
        minHeight: 48,
        paddingTop: 0,
        "& span": {
            flexDirection: "row"
        },
        "& span > *:first-child": {
            marginBottom: "0px !important",
            marginRight: 6
        }
    }

}));

export function tabProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export function TabPanel(props: TabPanelProps) {
    // @ts-ignore
    const { children, value, index, ...other } = props;

    return (
        // @ts-ignore
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

/**
 * Wrapper of snackbar component
 */
export default function Issue(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const {issueId} = useParams();

    const {
        issues
    } = // @ts-ignore
        useSelector(state => state.claimit.issue);

    const tabId = // @ts-ignore
        useSelector(state => state.claimit.issue.detailTabId);

    const dispatch = useDispatch();

    // checks if issue is loaded -> if not triggers load
    useEffect(() => {
        if (!issues[issueId]) {
            dispatch(loadIssue(issueId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    // checks if issues events are loaded -> if not triggers load
    useEffect(() => {
        if (issues[issueId] && !issues[issueId].eventsLoading && issues[issueId].events === undefined) {
            dispatch(loadIssueEvents(issueId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issues]);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------
    
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(changeDetailTab(newValue))
    };

    const handleMoreClick = () => {

    };
    
    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------


    return (
        <Paper elevation={1} className={classes.wrapper}>
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar style={{padding: 0}}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {issues[issueId] ? issues[issueId].name : ""}
                    </Typography>
                    <div className={classes.grow} />
                    <div>
                        <IconButton
                            edge="start"
                            onClick={handleMoreClick}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
                <Tabs value={tabId} onChange={handleTabChange} scrollButtons="off" variant="fullWidth">
                    <Tab icon={<CircleIcon />} value={0} label={cs.issue_tab_title_1} {...tabProps(0)} className={classes.tab}/>
                    <Tab icon={<AttachmentIcon />} value={1} label={cs.issue_tab_title_2} {...tabProps(1)} className={classes.tab}/>
                    <Tab icon={<DiscussionIcon />} value={2} label={cs.issue_tab_title_3} {...tabProps(2)} className={classes.tab}/>
                </Tabs>
            </AppBar>

            <TabPanel value={tabId}
                // @ts-ignore
                      index={0}>
                <IssueDetails project={props.project} issue={issues[issueId] ? issues[issueId] : null}/>
            </TabPanel>
            <TabPanel value={tabId}
                // @ts-ignore
                      index={1}>
                <Attachments project={props.project} issue={issues[issueId] ? issues[issueId] : null}/>
            </TabPanel>
            <TabPanel value={tabId}
                // @ts-ignore
                      index={2}>
                <Discussion project={props.project} issue={issues[issueId] ? issues[issueId] : null}/>
            </TabPanel>

        </Paper>
    );
}

