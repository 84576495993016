import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Attachment(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.932 12.764">
        <path id="prefix__attachment" stroke={color} strokeWidth="0.5px" d="M4.951-9.725q.025 0 .025.013T5-9.7l.3.3a.1.1 0 0 1 .025.076.146.146 0 0 1-.051.1L1.295-5.205a2.576 2.576 0 0 0-.6.9A2.729 2.729 0 0 0 .457-3.25a2.594 2.594 0 0 0 .787 1.9 2.565 2.565 0 0 0 1.879.787 2.7 2.7 0 0 0 1.066-.249 2.693 2.693 0 0 0 .911-.61l.3-.33 6.855-6.83a1.805 1.805 0 0 0 .559-1.32 1.764 1.764 0 0 0-.559-1.308 1.826 1.826 0 0 0-1.32-.546 1.763 1.763 0 0 0-1.295.533l-6.83 6.8a.963.963 0 0 0-.279.711.974.974 0 0 0 .3.711.974.974 0 0 0 .711.3.865.865 0 0 0 .711-.279L8.76-7.49a.172.172 0 0 1 .127-.051.147.147 0 0 1 .1.025 1.174 1.174 0 0 1 .254.229.147.147 0 0 1 .025.1.219.219 0 0 1-.076.152L4.7-2.539a1.562 1.562 0 0 1-1.143.482 1.591 1.591 0 0 1-1.168-.482A1.591 1.591 0 0 1 1.9-3.707a1.648 1.648 0 0 1 .381-1.041l.051-.025v-.051l6.855-6.83a2.393 2.393 0 0 1 1.752-.736 2.38 2.38 0 0 1 1.765.736 2.414 2.414 0 0 1 .728 1.754 2.411 2.411 0 0 1-.711 1.777L5.535-.965a3.4 3.4 0 0 1-1.041.6 3.312 3.312 0 0 1-1.168.241 3.224 3.224 0 0 1-2.348-.968A3.161 3.161 0 0 1 0-3.428a3.266 3.266 0 0 1 .254-1.18 3.561 3.561 0 0 1 .609-1.054l3.961-3.986a.208.208 0 0 1 .127-.077z" transform="translate(.25 12.641)"/>
    </svg>
}