import {
    LABELS_CREATE_SUCCESS, LABELS_DELETE_SUCCESS,
    LABELS_FAILURE,
    LABELS_REQUEST,
    LABELS_SUCCESS
} from "../actions";
import {insertItem, insertItems, removeItem} from "../../../util/array";


const initialState = {
    labelsLoading: false,
    labels: {},
    labelIds: []
};

const label = (state = initialState, action: any) => {
    switch (action.type) {
        // --------------------------------------------------------
        // ---- LABELS LOAD
        // --------------------------------------------------------
        case LABELS_REQUEST:
            return {
                ...state,
                labelsLoading: true
            };
        case LABELS_SUCCESS:
            return {
                ...state,
                labelsLoading: false,
                labels: {
                    ...state.labels,
                    ...action.response.content.entities.label
                },
                labelIds: insertItems(state.labelIds, action.response.content.result)
            };
        case LABELS_FAILURE:
            return {
                ...state,
                labelsLoading: false
            };
        // --------------------------------------------------------
        // ---- LABEL CREATE
        // --------------------------------------------------------
        case LABELS_CREATE_SUCCESS:
            return {
                ...state,
                labels: {
                    ...state.labels,
                    ...action.response.entities.label
                },
                labelIds: insertItem(state.labelIds, action.response.result)
            };
        // --------------------------------------------------------
        // ---- LABEL DELETE
        // --------------------------------------------------------
        case LABELS_DELETE_SUCCESS:
            return {
                ...state,
                labels: {
                    ...state.labels,
                    [action.parentId.labelId]: null
                },
                labelIds: removeItem(state.labelIds, action.parentId.labelId)
            };
        default:
            return state
    }
};

export default label
