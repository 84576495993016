import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function PinInvert(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.986 15">
        <path d="M1.641 1.641A5.29 5.29 0 0 1 5.508 0a5.122 5.122 0 0 1 3.809 1.626 5.709 5.709 0 0 1 1.67 3.882 7.138 7.138 0 0 1-.571 2.651 13.854 13.854 0 0 1-1.363 2.593q-.791 1.172-1.6 2.153t-1.359 1.538L5.508 15q-.234-.205-.6-.571t-1.319-1.494a23.914 23.914 0 0 1-1.67-2.212A14.948 14.948 0 0 1 .6 8.188a7.053 7.053 0 0 1-.6-2.68 5.29 5.29 0 0 1 1.641-3.867zm1.933 5.654a2.645 2.645 0 0 0 1.934.82 2.645 2.645 0 0 0 1.934-.82 2.645 2.645 0 0 0 .82-1.934 2.6 2.6 0 0 0-.82-1.919 2.669 2.669 0 0 0-1.934-.806 2.669 2.669 0 0 0-1.934.806 2.6 2.6 0 0 0-.82 1.919 2.645 2.645 0 0 0 .82 1.934z"/>
    </svg>
}