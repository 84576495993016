import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Important(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.619 14.619">
        <path d="M7.295 14.62a7 7 0 0 1-5.156-2.154A7.076 7.076 0 0 1 0 7.295a7.027 7.027 0 0 1 2.139-5.156A7.027 7.027 0 0 1 7.295 0a7.076 7.076 0 0 1 5.171 2.139 7 7 0 0 1 2.153 5.156 7.076 7.076 0 0 1-2.139 5.171 7.037 7.037 0 0 1-5.185 2.154zm4.629-11.924A6.315 6.315 0 0 0 7.295.766a6.291 6.291 0 0 0-4.614 1.919 6.291 6.291 0 0 0-1.919 4.61 6.291 6.291 0 0 0 1.919 4.615 6.291 6.291 0 0 0 4.614 1.919 6.34 6.34 0 0 0 4.629-1.919 6.267 6.267 0 0 0 1.934-4.614 6.22 6.22 0 0 0-1.934-4.6zM6.8 10.866a.739.739 0 0 1-.176-.5.655.655 0 0 1 .19-.483.655.655 0 0 1 .483-.19.705.705 0 0 1 .5.19.631.631 0 0 1 .203.488.678.678 0 0 1-.205.5.678.678 0 0 1-.5.205.678.678 0 0 1-.495-.21zm1.11-7.5l-.205 5.482H7l-.32-5.482z"/>
    </svg>

}