import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle
} from "@material-ui/core";
import cs from "../../../localization/cs";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

interface ConfirmDialogProps {
    title: string,
    opened: boolean,
    text?: string,
    positiveButtonText?: string,
    negativeButtonText?: string,
    onNegative?: () => void,
    onPositive: () => void,
}

/**
 * Wrapper of snackbar component
 */
export default function ConfirmDialog(props: ConfirmDialogProps) {

    const {
        title,
        opened,
        text,
        positiveButtonText,
        negativeButtonText,
        onNegative,
        onPositive,
    } = props;

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={opened}
            // do not close dialog when click away to prevent losing filled data
            onClose={() => {
            }}
            aria-labelledby="project-dialog"
        >
            <DialogTitle>
                {title}
            </DialogTitle>

            {text ? <DialogContent>
                {text}
            </DialogContent>: null}
            <DialogActions>
                {onNegative ? <Button onClick={onNegative} variant="contained" color="primary">
                    {negativeButtonText || cs.general_action_close}
                </Button> : null}
                <Button onClick={onPositive} variant="contained" color="primary">
                    {positiveButtonText || cs.general_action_save}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

