import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import routes from '../model/routes';
import Authentication from "../app/authentication/components/Authentication";
import Dashboard from "../app/dashboard/components/Dashboard";
import {isAuthenticated, REDIRECT_URI_TAG} from "../session/Session";
import {useSelector} from "react-redux";

export default () => (
  <Switch>
      <Route exact path={routes.Authentication.route} >
          {isAuthenticated() ? <Redirect to={routes.Dashboard.route} /> : <Authentication />}
      </Route>
      <Route path={routes.Dashboard.route} >
          <RedirectAwareDashboard />
      </Route>
  </Switch>
);

// Component to remember page before authentication before redirection to Authentication
function RedirectAwareDashboard() {

    const path = useSelector(state => state.router.location.pathname);

    if (!isAuthenticated())  {
        localStorage.setItem(REDIRECT_URI_TAG, path);
        return <Redirect to={routes.Authentication.route} />;
    }  else {
        return <Dashboard />
    }
}
