import {HIDE_SNACK_BAR, SHOW_SNACK_BAR} from "../actions";
import {AUTHENTICATION_FAILURE} from "../../authentication/actions";
import cs from "../../../localization/cs";
import {CREATE_PROJECT_FAILURE} from "../../company/actions";

export const SnackBarStyles = {
  SUCCESS: "success",
  ERROR: "error"
};

const initialState = {
  message: '',
  duration: null,
  type: null
};

const snackbar = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_SNACK_BAR:
      return {
        ...state,
        message: action.message,
        duration: action.duration,
        type: action.snackType
      };
    case HIDE_SNACK_BAR:
      return {
        ...state,
        message: ''
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        message: cs.authorization_error,
        duration: action.duration,
        type: SnackBarStyles.ERROR
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        message: cs.project_form_error,
        duration: action.duration,
        type: SnackBarStyles.ERROR
      };
    default:
      return state
  }
};

export default snackbar
