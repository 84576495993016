import React from 'react';
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {addTokenToRoute} from "../../../session/Session";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        margin: 8
    },
    media: {
        height: 120,
    },
    title: {
        textAlign: "left",
        marginLeft: 15
    },
    itemTitle: {
        textAlign: "left",
        fontSize: 10,
        color: "#3c4858",
        marginBottom: 0
    },
    cardContent: {
        paddingBottom: 8,
        paddingTop: 8
    },
    actionButton: {
        margin: 0
    },
    buttonGridItem: {
        width: "20%"
    }
}));

/**
 * Wrapper of snackbar component
 */
export default function AttachmentItem(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const attachment = props.attachment;

    const handleClick = () => {
          console.log("clicked!")
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick}>
                <CardMedia
                    className={classes.media}
                    // TODO add placeholder image
                    image={attachment.file ? addTokenToRoute(attachment.file._links.self.href) : "/images/lul.jpg"}
                    title={attachment.file.fileName}
                    style={{backgroundColor: "#d8d8d8"}}
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="subtitle1" className={classes.itemTitle}>
                        {attachment.file.fileName}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

