import React from 'react';
import {
    CircularProgress,
    Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {ISSUE_EVENT_FILE} from "../enums/IssueEventType";
import AttachmentIcon from "../../core/components/icons/Attachment";
import AttachmentItem from "./AttachmentItem";
import cs from "../../../localization/cs";
import CardIconButton from "../../core/components/CardIconButton";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({

    stateColumn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left"
    }
}));


/**
 * Wrapper of snackbar component
 */
export default function Attachments(props: any) {

    const issue = props.issue;

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const {
        events
        //@ts-ignore
    } = useSelector(state => state.claimit.issue)


    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleAdd = () => {
        console.log("add!!")
    };

    /**
     * Gets file issue event that was created first (min createdAt)
     */
    const getIssueAttachments = () => {
        if (!issue || !issue.events) return null;

        return issue.events.map((id: string) => events[id]).filter((event: any) => event && event.type === ISSUE_EVENT_FILE)
    };


    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    let attachments = getIssueAttachments()

    return (
        props.issue && attachments !== null ? <Grid container>
            {attachments.map((a: any) => <Grid item xs={6} className={classes.stateColumn}>
                    <AttachmentItem
                        attachment={a}
                    />
                </Grid>
            )}
            <Grid item xs={6} className={classes.stateColumn}>
                <CardIconButton
                    onClick={handleAdd}
                    text={cs.issue_add_attachment}
                    icon={<AttachmentIcon variant="primary" size={40}/>}
                />
            </Grid>
        </Grid> : <CircularProgress style={{margin: 20}} size={30} key="progress-data"
                                    color="primary"/>
    );
}

