import {CALL_API, METHOD_DELETE, METHOD_GET, METHOD_POST} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";


// --------------------------------------------------------
// ---- Load project users with project id
// --------------------------------------------------------

export const PROJECT_USERS_REQUEST = "PROJECT_USERS_REQUEST";
export const PROJECT_USERS_SUCCESS = "PROJECT_USERS_SUCCESS";
export const PROJECT_USERS_FAILURE = "PROJECT_USERS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const projectUsersRequest = (projectId: string) => ({
    [CALL_API]: {
        types: [PROJECT_USERS_REQUEST, PROJECT_USERS_SUCCESS, PROJECT_USERS_FAILURE],
        endpoint: `projects/` + projectId + `/users`,
        params: {"entityFlag": "all", "expanded": "userDetails", "size": "999"},
        schema: Schemas.USERS,
        parentId: projectId,
        method: METHOD_GET,
    }
});

/**
 * Performs authentication request
 */
export const loadProjectUsers = (projectId: string) => (dispatch: any) => {
    return dispatch(projectUsersRequest(projectId))
};

// --------------------------------------------------------
// ---- Add project user
// --------------------------------------------------------

export const PROJECT_USERS_ADD_REQUEST = "PROJECT_USERS_ADD_REQUEST";
export const PROJECT_USERS_ADD_SUCCESS = "PROJECT_USERS_ADD_SUCCESS";
export const PROJECT_USERS_ADD_FAILURE = "PROJECT_USERS_ADD_FAILURE";


const projectUsersAddRequest = (projectId: string, user: any) => ({
    [CALL_API]: {
        types: [PROJECT_USERS_ADD_REQUEST, PROJECT_USERS_ADD_SUCCESS, PROJECT_USERS_ADD_FAILURE],
        endpoint: `projects/` + projectId + `/users`,
        body: user,
        parentId: projectId,
        method: METHOD_POST,
        paged: false
    }
});

/**
 * Performs add project request
 */
export const addProjectUser = (projectId: string, user: any) => (dispatch: any) => {
    return dispatch(projectUsersAddRequest(projectId, user))
};

// --------------------------------------------------------
// ---- Remove project user
// --------------------------------------------------------

export const PROJECT_USERS_REMOVE_REQUEST = "PROJECT_USERS_REMOVE_REQUEST";
export const PROJECT_USERS_REMOVE_SUCCESS = "PROJECT_USERS_REMOVE_SUCCESS";
export const PROJECT_USERS_REMOVE_FAILURE = "PROJECT_USERS_REMOVE_FAILURE";


const projectUsersRemoveRequest = (projectId: string, userId: string) => ({
    [CALL_API]: {
        types: [PROJECT_USERS_REMOVE_REQUEST, PROJECT_USERS_REMOVE_SUCCESS, PROJECT_USERS_REMOVE_FAILURE],
        endpoint: `projects/` + projectId + `/users`,
        body: { user: userId },
        parentId: {
            project: projectId,
            user: userId
        },
        method: METHOD_DELETE,
        paged: false
    }
});

/**
 * Performs remove project request
 */
export const removeProjectUser = (projectId: string, userId: string) => (dispatch: any) => {
    return dispatch(projectUsersRemoveRequest(projectId, userId))
};

// --------------------------------------------------------
// ---- Load company users with company id
// --------------------------------------------------------

export const COMPANY_USERS_REQUEST = "COMPANY_USERS_REQUEST";
export const COMPANY_USERS_SUCCESS = "COMPANY_USERS_SUCCESS";
export const COMPANY_USERS_FAILURE = "COMPANY_USERS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const companyUsersRequest = (companyId: string) => ({
    [CALL_API]: {
        types: [COMPANY_USERS_REQUEST, COMPANY_USERS_SUCCESS, COMPANY_USERS_FAILURE],
        endpoint: `companies/` + companyId + `/users`,
        params: {"entityFlag": "all", "expanded": "userDetails", "size": "999"},
        parentId: companyId,
        schema: Schemas.USERS,
        method: METHOD_GET,
    }
});

/**
 * Performs authentication request
 */
export const loadCompanyUsers = (companyId: string) => (dispatch: any) => {
    return dispatch(companyUsersRequest(companyId))
};