
export function insertItems(array: Array<any>, items: Array<any>): Array<any> {
    const distinct = (value: any, index: number, self: any) => {
        return self.indexOf(value) === index
    };

    return [
        ...array,
        ...items
    ].filter(distinct)
}

export function insertItem(array: Array<any>, item: any): Array<any> {
    if (array.indexOf(item) > -1) return [...array];
    return [
        ...array,
        item
    ]
}

export function removeItem(array: Array<any>, item: any): Array<any> {
    let index = array.indexOf(item);
    return [...array.slice(0, index), ...array.slice(index + 1)]
}