import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'

import {CircularProgress, Paper, Grid, TextField, Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import cs from "../../../localization/cs";
import {authenticate} from "../actions";
import classNames from "classnames"
import {getUserName, REDIRECT_URI_TAG, setUser} from "../../../session/Session";
import {push} from "connected-react-router";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    buttonRow: {
        display: "flex",
        justifyContent: "flex-end"
    },
    copyrightRow: {
        display: "flex",
        justifyContent: "center"
    },
    button: {

        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
        width: "100%"
    },
    error: {
        color: "#F44336"
    },
    progress: {

    },
    paper: {
        width: 400,
        padding: 20
    },
    wrapper: {
        width: "100%",
        height: window.innerHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));


/**
 * Wrapper of snackbar component
 */
export default function Authentication() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // @ts-ignore
    const authentication = useSelector(state => state.claimit.authentication);

    const [username, setUsername] = useState(getUserName() || "");
    const [usernameError, setUsernameError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (authentication.authenticationSuccess) {
            let redirectPath = localStorage.getItem(REDIRECT_URI_TAG);
            if (redirectPath) {
                dispatch(push(redirectPath));
                localStorage.removeItem(REDIRECT_URI_TAG)
            } else {
                window.location.reload()
            }
        }
    });

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const validate = () => {
        console.log("user: " + username + " - pass: " + password)
        if (username === "") {
            setUsernameError(true);
            return false
        }
        if (password === "") {
            setPasswordError(true);
            return false
        }

        return true
    };

    let handleSubmit = () => {
        setUsernameError(false);
        setPasswordError(false);

        if (validate()) {
            setUser(username);
            dispatch(authenticate(username, password))
        }
    };

    const handleUsernameChange = (event: any) => {
        setUsername(event.currentTarget.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleKeyDown = (event: any)=> {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <div className={classNames(classes.wrapper, "backgroundImage")}>
            {authentication.authenticationLoading ?
                <CircularProgress size={60} thickness={3} color="secondary" className={classes.progress}/> :
                <Paper className={classes.paper}>
                    <Typography variant="h5">{cs.authorization_title}</Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                id="user-name"
                                label={cs.authorization_username}
                                value={username}
                                error={authentication.authenticationError || usernameError}
                                onChange={handleUsernameChange}
                                margin="normal"
                                style={{width: '100%'}}
                                onKeyDown={handleKeyDown}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                type="password"
                                label={cs.authorization_password}
                                value={password}
                                error={passwordError}
                                onChange={handlePasswordChange}
                                margin="normal"
                                style={{width: '100%'}}
                                onKeyDown={handleKeyDown}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.buttonRow}>
                            <Button color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={handleSubmit}
                                    autoFocus>{cs.authorization_submit}</Button>
                        </Grid>
                        <Grid item xs={12} className={classes.copyrightRow}>
                            <Typography variant="subtitle2">© Managit s.r.o.</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                }
        </div>
    )
}

