import React, {useEffect} from 'react';
import {Grid} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import {Route, Switch, useParams} from "react-router";
import BreadCrumbs from "./BreadCrumbs";
import Filters from "./Filters";
import {useDispatch, useSelector} from "react-redux";
import {loadProject} from "../actions";
import Data from "./Data";
import ProjectDetail from "./ProjectDetail";
import routes from "../../../model/routes";
import PartDetail from "../../part/components/PartDetail";
import Issue from "../../issue/components/Issue";
import {CurrentPartResolver} from "./CurrentPartResolver";
import IssueDialog from "../../issue/components/IssueDialog";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
    grow: {
        flexGrow: 1,
        maxWidth: "calc(100% - 400px)",
    },
    detail: {
        width: 400
    }
}));

/**
 * Wrapper of snackbar component
 */
export default function Project() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const { projectId } = useParams();

    const {
        projects,
        currentPart
    } = // @ts-ignore
        useSelector(state => state.claimit.project);

    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!projects || !projects[projectId]) {
            dispatch(loadProject(projectId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={10}>
                        <BreadCrumbs
                            project={projects ? projects[projectId] : null}
                        />
                        <Filters
                            project={projects ? projects[projectId] : null}
                        />
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={classes.grow}>
                <Data
                    project={projects ? projects[projectId] : null}
                    partId={currentPart}
                />
            </Grid>

            <Switch>
                <Route path={routes.Part.route}>
                    <CurrentPartResolver
                        project={projects ? projects[projectId]: null}
                    />
                    <Grid item className={classes.detail}>
                        <PartDetail />
                    </Grid>
                </Route>
                <Route path={routes.Issue.route}>
                    <CurrentPartResolver
                        project={projects ? projects[projectId]: null}
                    />
                    <Grid item className={classes.detail}>
                        <Issue project={projects ? projects[projectId]: null}/>
                    </Grid>
                </Route>
                <Route path={routes.Project.route}>
                    <CurrentPartResolver
                        project={projects ? projects[projectId]: null}
                    />
                    <Grid item className={classes.detail}>
                        <ProjectDetail
                            project={projects ? projects[projectId]: null}
                        />
                    </Grid>
                </Route>
            </Switch>

            <IssueDialog />
        </Grid>
    );
}

