
export const getIconAttributes = (props: any, theme: any) => {
    return {
        color: props.color ? props.color : (props.variant ? getThemedColor(props.variant, theme) : "#ffffff"),
        size: props.size || 20,
        padding: props.padding || 2
    }
};

const getThemedColor = (variant: string, theme: any): string => {
    switch (variant) {
        case "primary":
            return theme.palette.primary.main
        case "secondary":
            return theme.palette.secondary.main
        case "default":
            return theme.palette.default.main
        default:
            return theme.palette.primary.main
    }
};