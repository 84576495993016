import {CALL_API, callSimpleRequest, METHOD_GET, METHOD_POST} from "../../../middleware/api";
import Schemas from "../../../middleware/schemas";
import {addPart} from "../../part/actions";


// --------------------------------------------------------
// ---- Load projects
// --------------------------------------------------------

export const PROJECTS_REQUEST = "PROJECTS_REQUEST";
export const PROJECTS_SUCCESS = "PROJECTS_SUCCESS";
export const PROJECTS_FAILURE = "PROJECTS_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const projectsRequest = (companyId: string, page: number) => ({
    [CALL_API]: {
        types: [PROJECTS_REQUEST, PROJECTS_SUCCESS, PROJECTS_FAILURE],
        endpoint: `companies/` + companyId + `/projects`,
        params: { "page": page },
        parentId: companyId,
        schema: Schemas.PROJECTS,
        method: METHOD_GET
    }
});

/**
 * Performs authentication request
 */
export const loadProjects = (companyId: string, page: number = 1) => (dispatch: any) => {
    return dispatch(projectsRequest(companyId, page))
};

// --------------------------------------------------------
// ---- Load companies
// --------------------------------------------------------

export const COMPANIES_REQUEST = "COMPANIES_REQUEST";
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS";
export const COMPANIES_FAILURE = "COMPANIES_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const companiesRequest = (page: number) => ({
    [CALL_API]: {
        types: [COMPANIES_REQUEST, COMPANIES_SUCCESS, COMPANIES_FAILURE],
        endpoint: `companies`,
        params: { "page": page },
        schema: Schemas.COMPANIES,
        method: METHOD_GET
    }
});

/**
 * Performs authentication request
 */
export const loadCompanies = (page: number = 1) => (dispatch: any) => {
    return dispatch(companiesRequest(page))
};

// --------------------------------------------------------
// ---- Load company
// --------------------------------------------------------

export const COMPANY_REQUEST = "COMPANY_REQUEST";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILURE = "COMPANY_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const companyRequest = (companyId: string) => ({
    [CALL_API]: {
        types: [COMPANY_REQUEST, COMPANY_SUCCESS, COMPANY_FAILURE],
        endpoint: `companies/` + companyId,
        schema: Schemas.COMPANY,
        method: METHOD_GET,
        paged: false
    }
});

/**
 * Performs authentication request
 */
export const loadCompany = (companyId: string) => (dispatch: any) => {
    return dispatch(companyRequest(companyId))
};

// --------------------------------------------------------
// ---- Create project
// --------------------------------------------------------

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

// Fetches a single repository from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const createProjectRequest = (companyId: string, partId: string, name: string) => ({
    [CALL_API]: {
        types: [CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE],
        endpoint: `projects`,
        parentId: companyId,
        schema: Schemas.PROJECT,
        body: {
            name: name,
            company: companyId,
            description: "",
            mainPart: partId
        },
        method: METHOD_POST,
        paged: false
    }
});

/**
 * Performs request
 */
export const createProject = (companyId: string, name: string) => (dispatch: any) => {
    return callSimpleRequest(
        `parts`,
        METHOD_POST,
        {
            name: name
        },
        {},
        true
    ).then(response => {
            dispatch(addPart(response))
            return dispatch(createProjectRequest(companyId, response.uuid, name))
        },
        error => {
            return dispatch({
                type: CREATE_PROJECT_FAILURE,
                error: error.message || 'Something bad happened'
            });
        })
};
