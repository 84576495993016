import cs from "../../../localization/cs";

export const ISSUE_EVENT_STATE = "State";
export const ISSUE_EVENT_FILE = "File";
export const ISSUE_EVENT_COMMENT = "Comment";

const getIssueStateTitle = (state: string): string => {
    switch (state) {
        case ISSUE_EVENT_STATE: return cs.issue_state_new;
        case ISSUE_EVENT_FILE: return cs.issue_state_check;
        case ISSUE_EVENT_COMMENT: return cs.issue_state_in_progress;
        default: return ""
    }
}


// noinspection JSUnusedGlobalSymbols
export const getIssueState = (state: string) => {
    return {
       title: getIssueStateTitle(state),
    }
}

