import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Pictures(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.494 14.766">
        <path d="M3.047 14.766L0 6.383l2.871-1.051.146.41-2.46.909 2.754 7.532 9.053-3.281.146.41zm11.253-2.93L1.875 8.701l2.168-8.7 12.451 3.135zM2.49 8.35l11.455 2.871 1.934-7.738L4.424.615zm4.072-3.6l-.234-.067.088-.352 1.758-.82.586.029.117-.322.439.029.117.059-.088.322 1.816.176 1 2.871-.293-.029-.82-2.578-1.995 1.323zm4.453-.5l.762 2.373-.5 1.67-1.055-.176.439-1.494-.615-.176-.437 1.581L8.2 7.793l-2.077-.91.586-1.99 2.373.59zM7.207 6.533l.234-.85-.615-.176-.234.791zm1.055.381l.264-.908-.732-.234-.264.879zm1.23.322l.234-.879-.761-.204-.265.908zm1.787.381l.205-.734-.584-.174-.234.791z"/>
    </svg>
}