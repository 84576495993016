
export default {
    // GENERAL
    general_action_cancel: "Zrušit",
    general_action_close: "Zavřít",
    general_action_save: "Uložit",
    general_action_archive: "Archivovat",
    // AUTHORIZATION
    authorization_title: "Přihlášení",
    authorization_username: "Email",
    authorization_password: "Heslo",
    authorization_submit: "Přihlásit",
    authorization_error: "Zadaný email nebo heslo není správné",
    // DASHBOARD
    dashboard_title: "Dashboard",
    dashboard_search: "Zadejte název issue, štítku...",
    dashboard_logout: "Odhlásit",
    // PROJECTS
    projects_title: "Projekty",
    // PROJECTS
    project_data_tab_0: "Složky",
    project_data_tab_1: "Vady",
    project_data_tab_2: "Mapa",
    project_data_table_name: "Název",
    project_data_table_id: "ID",
    project_data_table_supplier: "Řešitel",
    project_data_table_due: "Odstranění",
    project_data_table_labels: "Štítky",
    project_form_tab_info: "Informace",
    project_form_tab_users: "Uživatelé",
    project_form_tab_labels: "Štítky",
    project_form_title_new: "Vytvoření projektu",
    project_form_title_edit: "Editace projektu",
    project_form_create: "Vytvořit",
    project_form_edit: "Editovat",
    project_form_input_name: "Název projektu",
    project_form_input_name_error: "Musíte zadat název projektu",
    project_form_input_description: "Popis projektu",
    project_form_input_company: "Organizace",
    project_form_input_address: "Adresa projektu",
    project_form_input_street: "Ulice + číslo popisné",
    project_form_input_city: "Město",
    project_form_input_zip: "PSČ",
    project_form_input_image: "Nahrát ilustrační obrázek",
    project_form_input_plan: "Nahrát základní plán",
    project_form_input_map_position: "Umístění na mapě",
    project_form_input_label: "Vytvoření štítku",
    project_form_add_user: "Přidat uživatele",
    project_form_user_name: "Uživatel",
    project_form_user_rights: "Oprávnění",
    project_form_user_last_activity: "Poslední aktivita",
    project_form_add_label: "Vytvořit štítek",
    project_form_delete_label_text: "Opravdu si přejete smazat štítek",
    project_form_no_labels: "V projektu nejsou žádné štítky",
    project_form_error: "Projekt se nepodařilo vytvořit",
    // COMPANY
    company_projects_title: "Projekty orzanizace",
    // PART
    part_title: "Složka",
    // BREADCRUMBS
    breadcrumbs_arrow: "Šipka",
    // FILTER
    filter_labels: "Štítky",
    filter_labels_title: "Filtrovat štítky",
    filter_labels_desc: "Vyhledejte a označte štítky",
    filter_labels_none: "Žádné štítky nenalezeny",
    // ISSUE
    issue_tab_title_1: "Detaily",
    issue_tab_title_2: "Přílohy",
    issue_tab_title_3: "Diskuze",
    issue_state_new: "Nový",
    issue_state_check: "Check",
    issue_state_in_progress: "V řešení",
    issue_state_email: "Email",
    issue_state_irremovable: "Neodstranitelné",
    issue_state_rejected: "Zamítnuto",
    issue_state_closed: "Vyřešeno",
    issue_event_file: "Soubor",
    issue_event_state: "Stav",
    issue_event_comment: "Komentář",
    issue_current_state: "Aktuální stav",
    issue_add_attachment: "Připojit přílohu",
    issue_form_title: "Nová událost",
    issue_form_create: "Vytvořit událost",
    // ROLES
    role_assignee_admin: "Admin",
    role_assignee: "Zadavatel",
    role_supplier: "Dodavatel",
    role_investor: "Pozorovatel",
    // USER
    user_form_title_new: "Přidání uživatele",
    user_form_title_edit: "Editace uživatele",
    user_form_input_user: "Uživatel",
    user_form_input_role: "Role",
}