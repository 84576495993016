import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Info(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.293 13.293">
        <path
            d="M6.646 0a6.646 6.646 0 1 0 6.646 6.646A6.654 6.654 0 0 0 6.646 0zm.432 10.59c-.316.053-.944.184-1.263.211a.778.778 0 0 1-.68-.354.831.831 0 0 1-.1-.761l1.256-3.455H4.985a1.561 1.561 0 0 1 1.229-1.452 4.81 4.81 0 0 1 1.263-.209 1 1 0 0 1 .68.354.831.831 0 0 1 .1.761L7 9.139h1.308a1.471 1.471 0 0 1-1.229 1.451zm.4-6.436a.831.831 0 1 1 .831-.831.831.831 0 0 1-.831.831z"/>
    </svg>
}