import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function FolderNew(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.234 12.305">
        <path d="M14.3 0a.918.918 0 0 1 .645.278.85.85 0 0 1 .293.63v10.459a.918.918 0 0 1-.278.645.879.879 0 0 1-.659.293H.938a.918.918 0 0 1-.645-.279.879.879 0 0 1-.293-.659V2.754a.877.877 0 0 1 .264-.645.913.913 0 0 1 .674-.264h6.767a.849.849 0 0 0 .571-.264 1.126 1.126 0 0 0 .278-.439l.059-.234A1.346 1.346 0 0 1 9.99 0zm0 2.842V.908h-4.222q-.469-.059-.7.557a1.825 1.825 0 0 1-1.67 1.377H.938v8.613H14.3zm-8.584 2.2a2.55 2.55 0 0 1 1.9-.82 2.548 2.548 0 0 1 1.875.762 2.831 2.831 0 0 1 .822 1.932 2.611 2.611 0 0 1-.791 1.89 2.572 2.572 0 0 1-1.9.806 2.572 2.572 0 0 1-1.9-.806 2.611 2.611 0 0 1-.8-1.89 2.562 2.562 0 0 1 .791-1.877zm3.132 2.253H8.76v-.85h-.85v-.85h-.762v.938H6.3v.762h.938v.85H8v-.85z"/>
    </svg>
}