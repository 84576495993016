import React from 'react';
import {AppBar, IconButton, Paper, Toolbar, Typography} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        margin: "10px 20px 10px 10px",
        height: window.innerHeight - 200
    },
    appBar: {
        borderRadius: "4px 4px 0px 0px",
        background: "#0f5c98"
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        marginLeft: 20
    },
    tab: {
        minWidth: 120
    }

}));

/**
 * Wrapper of snackbar component
 */
export default function ProjectDetail(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const handleMoreClick = () => {

    };
    
    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Paper elevation={1} className={classes.wrapper}>
            <AppBar position="relative" className={classes.appBar}>
                <Toolbar style={{padding: 0}}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Project detail
                    </Typography>
                    <div className={classes.grow} />
                    <div>
                        <IconButton
                            edge="start"
                            onClick={handleMoreClick}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

        </Paper>
    );
}

