import {
    COMPANY_USERS_SUCCESS,
    PROJECT_USERS_ADD_SUCCESS, PROJECT_USERS_REMOVE_SUCCESS,
    PROJECT_USERS_SUCCESS
} from "../actions";
import {insertItem, insertItems, removeItem} from "../../../util/array";

const initialState = {
    companyUsers: {},
    companyUserIds: [],
    projectUsers: {},
    projectUserIds: [],
};

const user = (state = initialState, action: any) => {
    switch (action.type) {
        // --------------------------------------------------------
        // ---- PROJECT USERS
        // --------------------------------------------------------
        case PROJECT_USERS_SUCCESS:
            return {
                ...state,
                projectUsers: {
                    ...state.projectUsers,
                    ...action.response.content.entities.user
                },
                projectUserIds: insertItems(state.projectUserIds, action.response.content.result)
            };

        case PROJECT_USERS_ADD_SUCCESS:

            // @ts-ignore
            let user = state.companyUsers[action.response.user];

            if (!user) {
                return {...state}
            }

            return {
                ...state,
                projectUsers: {
                    ...state.projectUsers,
                    [action.response.user]: {
                        user: { ...user.user },
                        rules: action.response.rules
                    }
                },
                projectUserIds: insertItem(state.projectUserIds, action.response.user)
            };
        case PROJECT_USERS_REMOVE_SUCCESS:

            return {
                ...state,
                projectUsers: {
                    ...state.projectUsers,
                    [action.parentId.user]: null
                },
                projectUserIds: removeItem(state.projectUserIds, action.parentId.user)
            };
        // --------------------------------------------------------
        // ---- COMPANY USERS
        // --------------------------------------------------------
        case COMPANY_USERS_SUCCESS:
            return {
                ...state,
                companyUsers: {
                    ...state.companyUsers,
                    ...action.response.content.entities.user
                },
                companyUserIds: insertItems(state.companyUserIds, action.response.content.result)
            };
        default:
            return state
    }
};

export default user
