import React, {useEffect} from 'react';
import {Paper, Grid, Checkbox, CircularProgress, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import cs from "../../../localization/cs";
import {loadPart, loadPartPlanLatest, loadParts, loadPartsIssues} from "../../part/actions";
import InfiniteScroll from "react-infinite-scroller";
import PartRow from "./PartRow";
import IssueRow from "./IssueRow";
import {push} from "connected-react-router";
import routes from "../../../model/routes";
import {addTokenToRoute} from "../../../session/Session";
import {ImageMap} from "./ImageMap"
import AddPartIcon from "../../core/components/icons/FolderNew"
import AddIssueIcon from "../../core/components/icons/New"
import {openIssueDialog} from "../../issue/actions";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        margin: "10px 10px 10px 20px",
        height: window.innerHeight - 200
    },
    headerRow: {
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2)",
        padding: 10
    },
    row: {
        justifyContent: "center",
        alignItems: "center"
    },
    infiniteWrapper: {
        height: window.innerHeight - 262,
        width: "100%",
        overflow: "auto"
    },
    actionButton: {
        padding: 6
    }
}));


function HeaderRow(props: any) {
    const classes = useStyles();

    return <Grid item xs={12} className={classes.headerRow}>
        <Grid container className={classes.row}>
            <Grid item xs={1}>
                <Checkbox
                    color="primary"
                    checked={props.checked}
                    onChange={props.onChange}
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
            </Grid>
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={3}>
                {cs.project_data_table_name}
            </Grid>
            {/*<Grid item xs={1}>*/}
            {/*    {cs.project_data_table_id}*/}
            {/*</Grid>*/}
            <Grid item xs={2}>
                {cs.project_data_table_supplier}
            </Grid>
            <Grid item xs={1}>
                {cs.project_data_table_due}
            </Grid>
            <Grid item xs={3}>
                {cs.project_data_table_labels}
            </Grid>
            <Grid item xs={1} style={{display: "flex"}}>
                <IconButton onClick={props.onAddPart} className={classes.actionButton}>
                    <AddPartIcon size={22} variant="primary"/>
                </IconButton>
                <IconButton onClick={props.onAddIssue} className={classes.actionButton}>
                    <AddIssueIcon size={22} variant="primary"/>
                </IconButton>
            </Grid>
        </Grid>
    </Grid>
}

/**
 * Wrapper of snackbar component
 */
export default function Data(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // @ts-ignore
    const {parts, plans} = useSelector(state => state.claimit.part);

    // @ts-ignore
    const {issues} = useSelector(state => state.claimit.issue);

    // @ts-ignore
    const {dataTabId, currentPart, currentIssue} = useSelector(state => state.claimit.project);

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentPart === null) return;

        if (!parts[currentPart]) {
            dispatch(loadPart(currentPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPart]);

    useEffect(() => {
        if (currentPart === null) return;
        let part = parts[currentPart]
        if (dataTabId === 2 && part && !part.planHead) {
            dispatch(loadPartPlanLatest(currentPart))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabId]);

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const PART_TYPE = 0;
    const ISSUE_TYPE = 1;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newChecked = event.target.checked;
        console.log(newChecked)

        // TODO handle checkbox
    };

    const loadItems = () => {

        if (dataTabId === 0) {
            if (parts[currentPart].partItemsPage !== undefined) {
                dispatch(loadParts(currentPart || "", parts[currentPart].partItemsPage.number + 1));
            } else {
                dispatch(loadParts(currentPart || "", 0));
            }
        } else {
            if (parts[currentPart].partIssuesPage !== undefined) {
                dispatch(loadPartsIssues(currentPart || "", parts[currentPart].partIssuesPage.number + 1));

            } else {
                dispatch(loadPartsIssues(currentPart || "", 0));
            }
        }
    };

    /**
     * Creates all items -> merges parts and issues into single array, adding each object a type of PART_TYPE or ISSUE_TYPE
     */
    const createItems = (): Array<any> => {

        let part = parts[currentPart];

        if (!part || !part.partItems) return [];

        let partObjects = part.partItems.partIds ? part.partItems.partIds.map((id: string) => {
            return {
                ...parts[id],
                type: PART_TYPE
            }
        }) : []

        let issueObjects = part.partItems.issueIds ? part.partItems.issueIds.map((id: string) => {
            return {
                ...issues[id],
                type: ISSUE_TYPE
            }
        }) : []

        switch (dataTabId) {
            case 0:
                return [
                    ...partObjects,
                    ...issueObjects.filter((issue: any) => issue.part === currentPart)
                ]
            case 1:
                return issueObjects
            case 2:
                return issueObjects.filter((issue: any) => issue.part === currentPart && issue.planLocation)
            default:
                return []
        }
    };

    const hasMore = (): boolean => {

        if (currentPart === null) return false;

        let part = parts[currentPart];
        if (dataTabId === 0) {
            return part && !part.partItemsLoading && (
                (part.partItemsPage !== undefined && (part.partItemsPage.number + 1 < part.partItemsPage.totalPages))
                || (part.partItemsPage === undefined)
            )
        } else {
            return part && !part.partIssuesLoading && (
                (part.partIssuesPage !== undefined && (part.partIssuesPage.number + 1 < part.partIssuesPage.totalPages))
                || (part.partIssuesPage === undefined)
            )
        }
    };

    const isLoading = (): boolean => {
        if (currentPart === null) return true;

        let part = parts[currentPart];
        return part && part.partItemsLoading
    };

    const handlePartClick = (partId: string) => {
        dispatch(push(routes.Part.createRoute(props.project.uuid, partId)));
    };

    const handleIssueClick = (issueId: string, partId: string) => {
        dispatch(push(routes.Issue.createRoute(props.project.uuid, issueId)));
    };

    const handleAddPart = () => {
        console.log("adding part!!")
    };

    const handleAddIssue = () => {
        dispatch(openIssueDialog())
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    const renderData = () => {
        switch (dataTabId) {
            // first two tabs show list of items
            case 0:
            case 1:
                return <Grid container>
                    <HeaderRow
                        checked={props.checked}
                        onChange={handleChange}
                        onAddPart={handleAddPart}
                        onAddIssue={handleAddIssue}
                    />
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadItems}
                        hasMore={hasMore()}
                        className={classes.infiniteWrapper}
                    >
                        {createItems().map((item: any) =>
                            item.type === PART_TYPE ?
                                <PartRow part={item} key={item.uuid} onClick={handlePartClick}/> :
                                <IssueRow issue={item} key={item.uuid} onClick={handleIssueClick}
                                          active={item.uuid === currentIssue}/>
                        )}
                        {isLoading() ? <CircularProgress style={{margin: 20}} size={30} key="progress-data"
                                                         color="primary"/> : null}
                    </InfiniteScroll></Grid>;
            // the last one shows plan
            case 2:
                let part = parts[currentPart]
                let planHead = (part && part.planHead) ? part.planHead : null;
                let planLink = (planHead && plans[planHead] && plans[planHead].file) ? plans[planHead].file["_links"].self.href : null;
                return planLink ? <ImageMap
                    link={addTokenToRoute(planLink)}
                    issues={createItems()}
                    onIssueClick={handleIssueClick}
                /> : <span>No plan!!</span>
        }
    };

    return (
        <Paper elevation={1} className={classes.wrapper}>
            {currentPart !== null ? renderData() : null}
        </Paper>
    );
}

