import React from "react";
import {
    createMuiTheme
} from "@material-ui/core/styles";
import {
    MuiThemeProvider,
    CssBaseline,
} from "@material-ui/core";
import Routes from "./router/Routes";
import SnackBar from "./app/core/components/SnackBar";
import {ConnectedRouter} from "connected-react-router";
import {history} from "./store/store";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0f5184"
        },
        secondary: {
            main: "#fff"
        },
        background: {
            default: "#fff"
        }
    }
});

const App = () => (
    <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <div style={{height: "100%", background: "#ff00ff"}}>
                <Routes/>
                <SnackBar/>
            </div>
        </MuiThemeProvider>
    </ConnectedRouter>
);

export default App;
