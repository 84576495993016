import {
    PROJECTS_REQUEST,
    PROJECTS_SUCCESS,
    PROJECTS_FAILURE,
    COMPANIES_REQUEST,
    COMPANIES_SUCCESS,
    COMPANIES_FAILURE,
    COMPANY_SUCCESS, CREATE_PROJECT_SUCCESS
} from "../actions";
import {insertItem, insertItems} from "../../../util/array";
import {
    COMPANY_USERS_FAILURE,
    COMPANY_USERS_REQUEST, COMPANY_USERS_SUCCESS,
} from "../../user/actions";

const initialState = {
    companies: {},
    companyIds: [],
    companyPages: null,
    companiesLoading: false,
    companiesError: false,
};

const project = (state = initialState, action: any) => {
    switch (action.type) {

        // --------------------------------------------------------
        // ---- COMPANIES
        // --------------------------------------------------------
        case COMPANIES_REQUEST:
            return {
                ...state,
                companiesLoading: true,
                companiesError: false
            };
        case COMPANIES_SUCCESS:
            return {
                ...state,
                companiesLoading: false,
                companiesError: false,
                companies: action.response.content.entities.company,
                companyIds: insertItems(state.companyIds, action.response.content.result),
                companyPages: action.response.page
            };
        case COMPANIES_FAILURE:
            return {
                ...state,
                companiesLoading: false,
                companiesError: true
            };

        // --------------------------------------------------------
        // ---- COMPANY
        // --------------------------------------------------------

        case COMPANY_SUCCESS:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    ...action.response.entities.company
                },
                companyIds: insertItem(state.companyIds, action.response.result)
            };
        // --------------------------------------------------------
        // ---- PROJECTS
        // --------------------------------------------------------
        case PROJECTS_REQUEST:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        projectsLoading: true,
                    }
                }
            };
        case PROJECTS_SUCCESS:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        // @ts-ignore
                        projectIds: insertItems(state.companies[action.parentId].projectIds || [], action.response.content.result),
                        projectPage: action.response.page,
                        projectsLoading: false,
                    }
                }
            };
        case PROJECTS_FAILURE:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        projectsLoading: false,
                    }
                }
            };
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        // @ts-ignore
                        projectIds: insertItem(state.companies[action.parentId].projectIds || [], action.response.result),
                    }
                }
            }
        // --------------------------------------------------------
        // ---- USERS
        // --------------------------------------------------------
        case COMPANY_USERS_REQUEST:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        usersLoading: true
                    }
                }
            };

        case COMPANY_USERS_SUCCESS:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        userIds: [...action.response.content.result],
                        usersLoading: false
                    }
                }
            };

        case COMPANY_USERS_FAILURE:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.companies[action.parentId],
                        usersLoading: false
                    }
                }
            };
        default:
            return state
    }
};

export default project
