import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Folder(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.088 12.422">
        <path d="M13.711 1.406a1.36 1.36 0 0 1 .981.381 1.218 1.218 0 0 1 .4.908v8.35a1.333 1.333 0 0 1-.4.981 1.333 1.333 0 0 1-.981.4H1.377a1.333 1.333 0 0 1-.977-.4 1.333 1.333 0 0 1-.4-.981V1.406a1.382 1.382 0 0 1 .4-1 1.308 1.308 0 0 1 .981-.41h3.4a2 2 0 0 1 1.377.7 2.026 2.026 0 0 0 1.406.7zm.7 9.521V4.072H.7v6.855q0 .7.674.7h12.337a.678.678 0 0 0 .5-.205.678.678 0 0 0 .203-.494zm0-7.529v-.615q0-.674-.7-.674H7.559a2.267 2.267 0 0 1-1.685-.7q-.688-.7-1.1-.7h-3.4q-.674 0-.674.7v1.992z"/>
    </svg>
}