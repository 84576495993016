import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Export(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding} xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15.47 14.109">
        <path
            d="M15.47 7.05L9.041 0v4.206H7.674A7.674 7.674 0 0 0 0 11.88v2.229l.607-.665a10.9 10.9 0 0 1 8.047-3.55h.387V14.1zm0 0"/>
    </svg>
}