import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Pin(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.635 15">
        <path d="M8.438 2.227A4.236 4.236 0 0 0 5.3.879a4.211 4.211 0 0 0-3.12 1.333A4.423 4.423 0 0 0 .879 5.42a7.422 7.422 0 0 0 .894 3.237 18.166 18.166 0 0 0 1.743 2.915q.849 1.114 1.784 2.139Q9.756 8.877 9.756 5.42a4.349 4.349 0 0 0-1.318-3.193zM7.266 7.148a2.678 2.678 0 0 1-1.963.82 2.63 2.63 0 0 1-1.948-.82 2.7 2.7 0 0 1-.806-1.963 2.654 2.654 0 0 1 .806-1.948A2.654 2.654 0 0 1 5.3 2.432a2.7 2.7 0 0 1 1.963.806 2.63 2.63 0 0 1 .82 1.948 2.678 2.678 0 0 1-.817 1.962zM1.523 1.582A5.1 5.1 0 0 1 5.3 0a5.1 5.1 0 0 1 3.779 1.582 5.276 5.276 0 0 1 1.556 3.838 7.7 7.7 0 0 1-.542 2.68 12.464 12.464 0 0 1-1.333 2.623q-.791 1.172-1.582 2.168t-1.319 1.552L5.3 15l-.569-.586q-.369-.381-1.274-1.509a26.764 26.764 0 0 1-1.611-2.212A13.932 13.932 0 0 1 .571 8.13 7.587 7.587 0 0 1 0 5.42a5.329 5.329 0 0 1 1.523-3.838zm2.432 4.951A1.836 1.836 0 0 0 5.3 7.09a1.836 1.836 0 0 0 1.35-.557 1.836 1.836 0 0 0 .557-1.348 1.788 1.788 0 0 0-.557-1.333 1.861 1.861 0 0 0-1.35-.541 1.812 1.812 0 0 0-1.333.542 1.812 1.812 0 0 0-.542 1.333 1.888 1.888 0 0 0 .53 1.347z"/>
    </svg>

}