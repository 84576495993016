import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Map(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.354 13.494">
        <path id="prefix__map" stroke="#000" strokeWidth="0.5px" d="M12.746-11.6a.144.144 0 0 1 .1.178V-.178q0 .2-.229.152l-4.06-1.193H8.48L4.443-.127h-.1L.1-1.4a.136.136 0 0 1-.1-.149v-11.273q0-.229.229-.178l4.139 1.27h.076l4.063-1.092h.076zm-8.15.33V-.66l3.656-.965v-10.639zM.482-12.416v10.639L4.113-.686V-11.3zM12.391-.559V-11.2L8.76-12.289V-1.65z" transform="translate(.25 13.26)"/>
    </svg>
}