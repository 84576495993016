import React from 'react';
import {
    CardActionArea,
    Card, Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    addButtonCard: {
        width: "100%",
        height: 153,
        margin: 8
    },
    addButtonWrapper: {
        textAlign: "center",
        height: "100%",
    },
    addButtonText: {
        marginTop: 6,
        fontSize: 16,
        color: theme.palette.primary.main,
    }
}));

interface CardIconProps {
    text: string,
    onClick: () => void,
    style?: any,
    fontStyle?: any,
    icon: JSX.Element | null
}

export default function CardIconButton(props: CardIconProps) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return <Card className={classes.addButtonCard} style={props.style}>
        <CardActionArea onClick={props.onClick} className={classes.addButtonWrapper}>
            {props.icon ? props.icon : null}
            <Typography variant="h4" className={classes.addButtonText} style={props.fontStyle}>
                {props.text || ""}
            </Typography>
        </CardActionArea>
    </Card>

}

