import {
    PARTS_REQUEST,
    PARTS_SUCCESS,
    PARTS_FAILURE,
    PART_SUCCESS,
    PART_BRANCH_SUCCESS,
    PART_BRANCH_REQUEST,
    PART_BRANCH_FAILURE,
    PARTS_ISSUES_SUCCESS,
    PARTS_ISSUES_REQUEST,
    PARTS_ISSUES_FAILURE,
    PART_PLAN_LATEST_SUCCESS, PART_ADD
} from "../actions";
import {insertItem, insertItems} from "../../../util/array";
import {normalize} from "normalizr";
import schemas from "../../../middleware/schemas";

const initialState = {
    parts: {},
    partIds: [],
    partBranchLoading: false,
    plans: {},
    planIds: []
};

const part = (state = initialState, action: any) => {
    switch (action.type) {

        // --------------------------------------------------------------------------------
        // -- PARTS
        // --------------------------------------------------------------------------------
        case PARTS_REQUEST:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partItemsLoading: true
                    }
                },
            };
        case PARTS_SUCCESS:

            let normalizedParts = normalize(action.response.content.parts, schemas.PARTS);
            let normalizedIssues = normalize(action.response.content.issues, schemas.ISSUES);

            // @ts-ignore
            let oldPartIds = state.parts[action.parentId] && state.parts[action.parentId].partItems ?
                // @ts-ignore
                (state.parts[action.parentId].partItems.partIds || []) : [];

            // @ts-ignore
            let oldIssueIds = state.parts[action.parentId] && state.parts[action.parentId].partItems ?
                // @ts-ignore
                (state.parts[action.parentId].partItems.issueIds || []) : [];

            return {
                ...state,
                parts: {
                    ...state.parts,
                    ...normalizedParts.entities.part,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partItems: {
                            partIds: insertItems(oldPartIds, normalizedParts.result),
                            issueIds: normalizedIssues.result ? insertItems(oldIssueIds, normalizedIssues.result) : [...oldIssueIds],
                        },
                        partItemsPage: action.response.page,
                        partItemsLoading: false
                    }
                },
                partIds: insertItems(insertItem(state.partIds, action.parentId), normalizedParts.result),

            };
        case PARTS_FAILURE:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partItemsLoading: false
                    }
                },
            };
        // --------------------------------------------------------------------------------
        // -- PARTS ISSUES
        // --------------------------------------------------------------------------------
        case PARTS_ISSUES_REQUEST:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partIssuesLoading: true
                    }
                },
            };
        case PARTS_ISSUES_SUCCESS:

            console.log(action);

            // @ts-ignore
            let oldIds = state.parts[action.parentId] && state.parts[action.parentId].partItems ?
                // @ts-ignore
                (state.parts[action.parentId].partItems.issueIds || []) : [];

            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partItems: {
                            // @ts-ignore
                            ...state.parts[action.parentId].partItems,
                            issueIds: insertItems(oldIds, action.response.content.result),
                        },
                        partIssuesPage: action.response.page,
                        partIssuesLoading: false
                    }
                },
            };
        case PARTS_ISSUES_FAILURE:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        // @ts-ignore
                        ...state.parts[action.parentId],
                        partIssuesLoading: false
                    }
                },
            };
        // --------------------------------------------------------------------------------
        // -- PART
        // --------------------------------------------------------------------------------
        case PART_SUCCESS:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    ...action.response.entities.part
                },
                partIds: insertItem(state.partIds, action.response.result),
            };
        case PART_ADD:
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.data.uuid]: action.data
                },
                partIds: insertItem(state.partIds, action.data.uuid),
            }
        // --------------------------------------------------------------------------------
        // -- PART BRANCH
        // --------------------------------------------------------------------------------
        case PART_BRANCH_REQUEST: {
            return {
                ...state,
                partBranchLoading: true,
            }
        }
        case PART_BRANCH_SUCCESS:
            let normalized = normalize(action.response.content, schemas.PARTS);
            return {
                ...state,
                parts: {
                    ...state.parts,
                    ...normalized.entities.part
                },
                partIds: insertItems(state.partIds, normalized.result),
                partBranchLoading: false,
            };
        case PART_BRANCH_FAILURE: {
            return {
                ...state,
                partBranchLoading: false,
            }
        }
        // --------------------------------------------------------------------------------
        // -- PART PLAN
        // --------------------------------------------------------------------------------
        case PART_PLAN_LATEST_SUCCESS: {
            return {
                ...state,
                parts: {
                    ...state.parts,
                    [action.parentId]: {
                        //@ts-ignore
                        ...state.parts[action.parentId],
                        planHead: action.response.result
                    }
                },
                plans: {
                    ...state.plans,
                    ...action.response.entities.plan
                },
                planIds: insertItem(state.planIds, action.response.result)
            }
        }
        default:
            return state
    }
};

export default part
