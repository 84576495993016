import React, {useState} from 'react';
import {
    CircularProgress,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import {useSelector} from "react-redux";
import {ISSUE_EVENT_FILE} from "../enums/IssueEventType";
import DiscussionItem from "./DiscussionItem";
import AttachmentIcon from "../../core/components/icons/Attachment";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    inputRow: {
        position: "absolute",
        bottom: 0,
        left: 0,
        display: "flex",
        background: "#eff0f1",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        width: "100%",
        padding: 10,
        alignItems: "center"
    },
    initialsCircle: {
        width: 32,
        height: 32,
        background: theme.palette.primary.main,
        color: "white",
        borderRadius: 16,
        marginRight: 8,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    },
    textInput: {
        flexGrow: 1,
        background: "white"
    }
}));


/**
 * Wrapper of snackbar component
 */
export default function Discussion(props: any) {

    const issue = props.issue;

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const [message, setMessage] = useState("");

    const {
        events
        //@ts-ignore
    } = useSelector(state => state.claimit.issue)

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    /**
     * Gets file issue event that was created first (min createdAt)
     */
    const getIssueEvents = () => {
        if (!issue || !issue.events) return null;

        return issue.events.map((id: string) => events[id]).filter((event: any) => event && event.type !== ISSUE_EVENT_FILE)
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value)
    };

    const handleSubmit = () => {
        console.log("Submited: " + message)
        setMessage("")
    };

    const handleAttachment = () => {
        console.log("Attachment clicked!")
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    let issueEvents = getIssueEvents()

    return (
        props.issue && issueEvents !== null ? <div>
            <div>
                {issueEvents.map((e: any) =>
                    <DiscussionItem
                        event={e}
                    />
                )}
            </div>

            <div className={classes.inputRow}>
                <span className={classes.initialsCircle}>
                    <span>LS</span>
                </span>
                <FormControl className={classes.textInput}>
                    <OutlinedInput
                        id="standard-adornment-password"
                        value={message}
                        multiline
                        rowsMax={4}
                        onChange={handleMessageChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Submit message"
                                    onClick={handleSubmit}
                                >
                                    <Send />
                                </IconButton>
                            </InputAdornment>
                        }
                        style={{paddingRight: 0}}
                    />
                </FormControl>
                <IconButton
                    onClick={handleAttachment}
                >
                    <AttachmentIcon variant="primary"/>
                </IconButton>
            </div>
        </div> : <CircularProgress style={{margin: 20}} size={30} key="progress-data"
                                    color="primary"/>
    );
}

