import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Labels(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.654 13.222">
            <rect width="13.654" height="4.024" data-name="Rectangle 2" rx="2" transform="translate(0 4.599)"/>
            <rect width="9.558" height="4.024" data-name="Rectangle 2" rx="2" transform="translate(0 9.198)"/>
            <rect width="9.558" height="4.024" data-name="Rectangle 2" rx="2"/>
        </svg>
}