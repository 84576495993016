import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Trash(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.887 15.279">
        <g>
            <path d="M4.667.922h2.554v.43h.922v-.49A.863.863 0 0 0 7.28 0H4.605a.863.863 0 0 0-.862.862v.49h.922zm0 0"
                  data-name="Path 29" transform="translate(-.001)"/>
            <path
                d="M10.273 5.006H1.619a.406.406 0 0 0-.4.439l.724 8.95a.961.961 0 0 0 .958.884h6.1a.961.961 0 0 0 .958-.885l.724-8.95a.406.406 0 0 0-.41-.438zm-6.561 9.319h-.029a.461.461 0 0 1-.46-.433l-.454-7.347a.461.461 0 1 1 .921-.057l.453 7.347a.461.461 0 0 1-.431.49zm2.7-.46a.461.461 0 0 1-.922 0V6.517a.461.461 0 0 1 .922 0zm2.708-7.32l-.433 7.347a.461.461 0 0 1-.46.434h-.028a.461.461 0 0 1-.433-.487l.433-7.348a.461.461 0 0 1 .921.054zm0 0"
                data-name="Path 30" transform="translate(-.001)"/>
            <path
                d="M11.867 3.583l-.3-.908a.586.586 0 0 0-.556-.4H.879a.586.586 0 0 0-.556.4l-.3.908a.381.381 0 0 0 .361.5h11.122a.376.376 0 0 0 .2-.058.38.38 0 0 0 .161-.442zm0 0"
                data-name="Path 31" transform="translate(-.001)"/>
        </g>
    </svg>
}