import React, {useEffect} from 'react';
import {Box, Typography, CircularProgress, Grid, IconButton, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {idsToObjects} from "../../../middleware/schema/schemaUtil";
import InfiniteScroll from "react-infinite-scroller";
import {loadCompany, loadProjects} from "../actions";
import ProjectItem from "./ProjectItem";
import EditIcon from "../../core/components/icons/Edit";
import NewIcon from "../../core/components/icons/New";
import {openProjectDialog} from "../../project/actions";

// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        height: window.innerHeight - 100,
        overflow: "auto",
    },
    title: {
        textAlign: "left",
        marginLeft: 15
    },
    actionButton: {
        padding: 4
    }
}));

/**
 * Wrapper of snackbar component
 */
export default function Projects() {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const classes = useStyles();

    const {companyId} = useParams();

    const {
        companies,
    } = // @ts-ignore
        useSelector(state => state.claimit.company);

    // @ts-ignore
    const projects = useSelector(state => state.claimit.project.projects);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!companies[companyId]) {
            dispatch(loadCompany(companyId));
        }
    });

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const loadItems = (page: number) => {
        // pages starts from 0 on the server
        let normalizedPage = page - 1;

        dispatch(loadProjects(companyId, normalizedPage))
    };

    const handleSettings = () => {

    };

    const handleAdd = () => {
        dispatch(openProjectDialog(companyId))
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Container>
            <Box my={2} className={classes.wrapper}>
                <Typography variant="h5" className={classes.title}>
                    {companies[companyId] ? companies[companyId].name : ""}
                    <IconButton
                        onClick={handleSettings}
                        color="primary"
                        className={classes.actionButton}
                        style={{marginLeft: 4}}
                    >
                        <EditIcon variant="primary" size={22} />
                    </IconButton>
                    <IconButton
                        onClick={handleAdd}
                        color="primary"
                        className={classes.actionButton}
                    >
                        <NewIcon variant="primary" size={22} />
                    </IconButton>
                </Typography>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadItems}
                    hasMore={companies[companyId] && (!companies[companyId].projectsLoading &&
                        (!companies[companyId].projectPage ||
                            ((companies[companyId].projectPage.number + 1) !== companies[companyId].projectPage.totalPages)
                        ))}
                    style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        boxSizing: "border-box"
                    }}
                    useWindow={false}
                >
                    {companies[companyId] ? idsToObjects(companies[companyId].projectIds, projects).map((p: any) =>
                        <Grid item key={p.uuid}>
                            <ProjectItem project={p}/>
                        </Grid>
                    ) : null
                    }
                    {companies[companyId] && companies[companyId].projectsLoading ?
                        <Grid item xs={12} key="project-items-loader" style={{textAlign: "center"}}>
                            <CircularProgress size={30} color="primary" style={{margin: 20}}/>
                        </Grid> : null}
                </InfiniteScroll>
            </Box>
        </Container>
    );
}

