import React from 'react';
import {Grid, Checkbox, Chip, useTheme} from "@material-ui/core";
import { makeStyles} from "@material-ui/core/styles";
import PriorityIcon from "../../core/components/icons/Important";
import DueIcon from "../../core/components/icons/Clock";
import classNames from "classnames";
import moment from "moment";
import {getIssueState} from "../../issue/enums/IssueState";


// --------------------------------------------------------------------------------
// -- Styles
// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
    row: {
        padding: 10,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #eff0f1",
        cursor: "pointer",
        "&:hover": {
            background: "#eff0f1"
        },
    },
    active: {
        background: "#e0e0e0"
    },
    title: {
        fontWeight: "bold",
        textAlign: "left"
    },
    iconStyle: {
        color: "#3c4858"
    },
    iconWrapper: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    }
}));

const ICON_SIZE = 32

/**
 * Wrapper of snackbar component
 */
export default function IssueRow(props: any) {

    // --------------------------------------------------------------------------------
    // -- Hooks
    // --------------------------------------------------------------------------------

    const issue = props.issue;

    const theme = useTheme();

    const classes = useStyles();

    // --------------------------------------------------------------------------------
    // -- Private functions
    // --------------------------------------------------------------------------------

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newChecked = event.target.checked;
        console.log(newChecked)

        // TODO handle checkbox
    };

    const handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    };

    const handleClick = () => {
        props.onClick(props.issue.uuid);
    };

    const renderStateIcon = () => {

        if (!issue || !issue.state) return null;

        return getIssueState(issue.state.state).icon(ICON_SIZE)
    };

    // --------------------------------------------------------------------------------
    // -- Render
    // --------------------------------------------------------------------------------

    return (
        <Grid container className={classNames(classes.row, props.active ? classes.active : "normal")} onClick={handleClick}>
            <Grid item xs={1}>
                <Checkbox
                    checked={props.checked}
                    onChange={handleChange}
                    onClick={handleCheckboxClick}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Grid>
            <Grid item xs={1} className={classes.iconWrapper}>
                {renderStateIcon()}
            </Grid>
            <Grid item xs={3} className={classes.title}>
                {issue ? issue.name : "Vada"}
            </Grid>
            <Grid item xs={2}>
                {issue && issue.assigneeDetail ? issue.assigneeDetail.name + " " + issue.assigneeDetail.surname : ""}
            </Grid>
            <Grid item xs={1}>
                {issue && issue.dueDate ? moment(issue.dueDate).format('DD.MM.YY') : ""}
            </Grid>
            <Grid item xs={3} style={{textAlign: "left"}}>
                {issue && issue.labels ? issue.labels.map((label: any) =>
                    <Chip key={label.uuid} size="small" label={label.name} style={{
                        color: theme.palette.getContrastText(label.color),
                        background: label.color,
                        marginRight: 6}}/>
                ) : null}
            </Grid>
            <Grid item xs={1}>
                {issue && issue.priority === "Normal" ? <PriorityIcon variant="primary" className={classes.iconStyle}/> : null}
                {issue && issue.priority === "Normal" ? <DueIcon variant="primary" className={classes.iconStyle}/> : null}
            </Grid>
        </Grid>
    );
}

