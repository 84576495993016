import React from "react"
import {useTheme} from "@material-ui/core";
import {getIconAttributes} from "./utils";


export default function Logo(props: any) {
    const theme = useTheme();

    const {color, size, padding} = getIconAttributes(props, theme)

    return <svg style={{padding: padding}} fill={color} width={size + padding} height={size + padding}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.225 13.096">
        <path d="M7.441 4.336A1.942 1.942 0 0 0 5.42 2.843a2.255 2.255 0 0 0-1.685.674 2.567 2.567 0 0 0-.659 1.875 2.377 2.377 0 0 0 .688 1.8 2.308 2.308 0 0 0 1.655.659 2 2 0 0 0 2.11-1.494l2.7.85a5.133 5.133 0 0 1-.835 1.67A12.2 12.2 0 0 1 8.115 10.4q-.644.643-2.109 2.051-.439.41-.674.645-.586-.645-1.787-1.846T1.729 9.36A8.447 8.447 0 0 1 .557 7.588 4.764 4.764 0 0 1 0 5.39a5.2 5.2 0 0 1 1.553-3.853A5.169 5.169 0 0 1 5.332-.001 4.975 4.975 0 0 1 8.569 1.01a4.636 4.636 0 0 1 1.626 2.5z"/>
    </svg>
}