import {projectSchema} from "./schema/projectSchema";
import {partSchema} from "./schema/partSchema";
import {companySchema} from "./schema/companiesSchema";
import {issueSchema} from "./schema/issueSchema";
import {labelSchema} from "./schema/labelSchema";
import {planSchema} from "./schema/planSchema";
import {eventSchema} from "./schema/eventSchema";
import {userSchema} from "./schema/userSchema";

// Schemas for API responses.
export default {
    COMPANIES: [companySchema],
    COMPANY: companySchema,
    PROJECTS: [projectSchema],
    PROJECT: projectSchema,
    LABELS: [labelSchema],
    LABEL: labelSchema,
    PARTS: [partSchema],
    PART: partSchema,
    ISSUE: issueSchema,
    ISSUES: [issueSchema],
    PLAN: planSchema,
    EVENT: eventSchema,
    EVENTS: [eventSchema],
    USERS: [userSchema],
    USER: userSchema,
};