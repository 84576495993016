import cs from "../../../localization/cs";
import React from "react";
import StateNewIcon from "../../core/components/icons/StateNew";
import StateMailIcon from "../../core/components/icons/StateMail";
import StateEyeIcon from "../../core/components/icons/StateEye";
import StateBackIcon from "../../core/components/icons/StateBack";
import StateCrossIcon from "../../core/components/icons/StateCross";
import StateDoneIcon from "../../core/components/icons/StateDone";
import StateProgressIcon from "../../core/components/icons/StateProgress";


export const ISSUE_STATE_NEW = "New";
export const ISSUE_STATE_CHECK = "Check";
export const ISSUE_STATE_IN_PROGRESS = "InProgress";
export const ISSUE_STATE_EMAIL = "Email";
export const ISSUE_STATE_IRREMOVABLE = "Irremovable";
export const ISSUE_STATE_REJECTED = "Rejected";
export const ISSUE_STATE_CLOSED = "Closed";

const getIssueStateTitle = (state: string): string => {
    switch (state) {
        case ISSUE_STATE_NEW:
            return cs.issue_state_new;
        case ISSUE_STATE_CHECK:
            return cs.issue_state_check;
        case ISSUE_STATE_IN_PROGRESS:
            return cs.issue_state_in_progress;
        case ISSUE_STATE_EMAIL:
            return cs.issue_state_email;
        case ISSUE_STATE_IRREMOVABLE:
            return cs.issue_state_irremovable;
        case ISSUE_STATE_REJECTED:
            return cs.issue_state_rejected;
        case ISSUE_STATE_CLOSED:
            return cs.issue_state_closed;
        default:
            return ""
    }
}

const getIssueStateIcon = (state: string, size: number, padding: number | null, variant: string, color: string | null) => {
    switch (state) {
        case ISSUE_STATE_NEW:
            return <StateNewIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_CHECK:
            return <StateEyeIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_IN_PROGRESS:
            return <StateProgressIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_EMAIL:
            return <StateMailIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_IRREMOVABLE:
            return <StateCrossIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_REJECTED:
            return <StateBackIcon variant={variant} size={size} padding={padding} color={color}/>;
        case ISSUE_STATE_CLOSED:
            return <StateDoneIcon variant={variant} size={size} padding={padding} color={color}/>;
        default:
            return ""
    }
}

const getNextStates = (state: string, isSupplier: boolean) => {
    switch (state) {
        case ISSUE_STATE_NEW:
        case ISSUE_STATE_EMAIL:
            return {
                left: ISSUE_STATE_IRREMOVABLE,
                right: ISSUE_STATE_CLOSED
            }
        case ISSUE_STATE_IN_PROGRESS:
            if (isSupplier) {
                return {
                    left: ISSUE_STATE_REJECTED,
                    right: ISSUE_STATE_CHECK
                }
            } else {
                return {
                    left: ISSUE_STATE_IRREMOVABLE,
                    right: ISSUE_STATE_CLOSED
                }
            }
        case ISSUE_STATE_REJECTED:
            return {
                left: ISSUE_STATE_IN_PROGRESS,
                right: ISSUE_STATE_IRREMOVABLE
            }
        case ISSUE_STATE_CHECK:
            return {
                left: ISSUE_STATE_IN_PROGRESS,
                right: ISSUE_STATE_CLOSED
            }
        default:
            return null
    }
}

export const getIssueState = (state: string) => {
    return {
        title: getIssueStateTitle(state),
        icon: (size = 20, variant = "primary", color: string | null = null, padding = null) =>
            getIssueStateIcon(state, size, padding, variant, color),
        nextStates: (isSupplier: boolean) => getNextStates(state, isSupplier)
    }
}

