import {combineReducers} from 'redux'
import snackbar from "../app/core/reducers/snackbar";
import authentication from "../app/authentication/reducers";
import dashboard from "../app/dashboard/reducers";
import company from "../app/company/reducers";
import project from "../app/project/reducers";
import part from "../app/part/reducers";
import label from "../app/label/reducers";
import issue from "../app/issue/reducers";
import user from "../app/user/reducers";

const rootReducer = combineReducers({
  snackbar,
  authentication,
  dashboard,
  company,
  project,
  part,
  label,
  issue,
  user
});

export default rootReducer;
